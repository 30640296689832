<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    absolute
    fixed
  >
  <template v-slot:prepend>
    <v-list-item>
      <v-list-item-content>
        <v-col>
          <v-list-item-title class="pl-0">Ostatnie logowanie</v-list-item-title>
          <v-list-item-subtitle>
            {{ user.lastLogin}}
          </v-list-item-subtitle>
        </v-col>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-col>
          <v-list-item-title class="pl-0">Tryb ciemny</v-list-item-title>
          <v-switch
            class="mt-0"
            v-model="darkMode"
            :light="!darkMode"
            @change="toggleDarkMode"
          ></v-switch>
        </v-col>
      </v-list-item-content>
    </v-list-item>
    
  </template>

  <v-divider></v-divider>
  <v-list
      dense
      nav
    >
      <v-subheader></v-subheader>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
  export default {
    props: {

    },
    data() {
      return {
        drawer: false,
        darkMode: false,
      };
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
    },
    mounted() {
      this.darkMode = localStorage.getItem('darkMode') === 'true';
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      toggleDarkMode(){
         this.$emit('toggleDarkMode', this.darkMode);
      }
    },
  };
</script>
    
<style>

</style>