<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Lista użytkowników
              <v-spacer />
              <v-btn
                dark
                tile
                to="/users/add"
              >
                <v-icon left>
                  mdi-note-plus
                </v-icon>
                Dodaj użytkownika
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="this.users"
              class="elevation-1 ma-4 mt-10"
            >
              <template #[`item.role`]="{ item }">
                <span>
                  {{ item.roles }}
                </span>
              </template>
              <template #[`item.userDetails.active`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="activateUser(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="(item.userDetails.active) ? 'green' : 'grey'"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ (item.userDetails.active) ? 'wyłącz' : 'włącz' }}
                  </span>
                </v-tooltip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      link
                      :to="`/user/${item.id}/edit`"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edycja</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="openDeleteModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Usuń</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              transition="dialog-bottom-transition"
              v-model="deleteModal"
              max-width="600"
            >
              <template>
                <v-card>
                  <v-toolbar
                    color="secondary"
                  >Potwierdź usuwanie</v-toolbar>
                  <v-card-text>
                    <div class="text-p pa-12">
                      Czy usunąć uytkownika
                      <b>{{ (this.selectedUser) ? this.selectedUser.name : '' }}</b>
                      z listy?
                    </div>
                  </v-card-text>
                  <v-card-actions 
                    class="justify-end"
                  >
                    <v-btn
                      tile
                      @click="deleteModal = false"
                    >Anuluj</v-btn>
                    <v-btn
                      tile
                      color="secondary"
                      @click="deleteUser()"
                    >Usuń</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <v-snackbar
              v-model="snackbar"
              color="orange"
            >
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>
                  mdi-close-thick
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </template>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
  export default {
    name: 'Users',
    data() {
      return {
        headers: [
          { text: 'Id', value: 'id' },
          { text: 'Nazwa użytkownika',value: 'username' },
          { text: 'Imię',value: 'userDetails.firstName' },
          { text: 'Nazwisko',value: 'userDetails.lastName' },
          { text: 'Email', value: 'email' },
          { text: 'Role', value: 'roles' },
          { text: 'Aktywny', value: 'userDetails.active' },
          { text: 'Akcje', value : 'actions'}
        ],
        users: [],
        snackbar: false,
        snackbarText: '',
        deleteModal: false,
        selectedUser: null,
        loading: false,
      };
    },
    mounted() {
      this.loading = true;
      this.fetchUsers();
    },
    methods: {
      fetchUsers() {
        this.axios.get('/api/users')
        .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.users = data;
            this.snackbar = true;
            this.snackbarText = 'Pobrano dane';
            this.loading = false;
        });
      },
      activateUser(item) {
        const postData = {
          active: (item.userDetails.active === 1) ? 0 : 1,
        }
        this.axios.patch(`/api/user/${item.id}/activate`, postData, { 'Content-Type': 'application/json' })
          .then(({ status }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.snackbar = true;
            this.snackbarText = 'Zapisano';
            this.fetchUsers();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      openDeleteModal(user) {
        this.deleteModal = true;
        this.selectedUser = user;
      },
      deleteUser() {
        const userId = this.selectedUser.id;
        this.axios.delete(`/api/user/${userId}`)
          .then(( {status} ) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.snackbar = true;
            this.snackbarText = 'Usunięto';
            this.fetchUsers();
            this.deleteModal = false;
        });
      },
      ...mapActions({
        storeLogout: 'auth/logout',
      }),
    },
  }
</script>
