<template>
  <v-card
    :class="
      $vuetify.breakpoint.xs ? 'text-center pl-2' : 'text-center pl-10 pr-10'
    "
    elevation="0"
    v-if="!loading"
  >
    <template>
      <v-card-title
        :class="
          $vuetify.breakpoint.xs
            ? 'text-center fullwidth mt-5 pl-0'
            : 'fullwidth mt-2 pl-0'
        "
      >
        Grupy haseł
        <v-spacer />
        <v-card-subtitle class="pr-0" style="text-align: right">
          data ważności modułu <br />
          <b>{{ this.expireDate }}</b>
        </v-card-subtitle>
      </v-card-title>
      <v-card class="d-flex justify-end" elevation="0">
        <v-card-actions>
          <v-btn
            dark
            tile
            to="/passwords/group/add"
            :loading="this.loading"
            :disabled="this.loading"
            class="secondary"
          >
            <v-icon left> mdi-note-plus </v-icon>
            Dodaj grupę
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="groups"
        :disable-sort="true"
        class="elevation-1 mt-10"
      >
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                link
                :to="`/passwords/group/${item.id}/edit`"
                @click.stop.prevent
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edycja</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                @click="openDeleteModal(item)"
                @click.stop.prevent
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Usuń</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="deleteModal"
        max-width="600"
      >
        <template>
          <v-card>
            <v-toolbar dark color="secondary">
              <v-toolbar-title>Potwierdź usuwanie</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div class="text-p pa-12">
                Czy usunąć grupę
                <b>{{ this.selectedGroup ? this.selectedGroup.name : "" }}</b>
                z listy?
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn tile @click="deleteModal = false">Anuluj</v-btn>
              <v-btn tile color="secondary" @click="deleteGroup()">Usuń</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-snackbar v-model="snackbar" color="orange">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" icon v-bind="attrs" @click="snackbar = false">
            <v-icon> mdi-close-thick </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="primary" :size="200" />
  </div>
</template>

<script>
export default {
  name: "Groups",
  data() {
    return {
      headers: [
        {
          text: "Nazwa",
          value: "name",
          width: 300,
        },
        {
          text: "Akcje",
          value: "actions",
          align: "right",
          width: 200,
        },
      ],
      loading: false,
      snackbar: false,
      snackbarText: "",
      deleteModal: false,
      selectedGroup: null,
      groups: [],
      expireDate: "-",
    };
  },
  watch: {},
  mounted() {
    this.fetchGroups();
  },
  methods: {
    openDeleteModal(group) {
      this.deleteModal = true;
      this.selectedGroup = group;
    },
    deleteGroup() {
      const groupId = this.selectedGroup.id;
      this.axios
        .delete(`/api/pzp/passwords-groups/${groupId}`)
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.deleteModal = false;
          this.snackbar = true;
          if (data === true) {
            this.snackbarText = "Usunięto";
          } else {
            this.snackbarText = "Nie usunięto, grupa trzyma hasła";
          }
          this.fetchGroups();
        });
    },
    fetchGroups() {
      this.loading = true;
      return new Promise((resolve) => {
        this.axios
          .get("/api/pzp/passwords-groups")
          .then(({ status, data }) => {
            if (status !== 200) {
              resolve(false);
              throw new Error("Wrong status code");
            }
            this.loading = false;
            this.groups = data.data;
            this.expireDate = data.expire_date;
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            console.log(err);
          });
      });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>