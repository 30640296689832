<template>
  <v-row :class="($vuetify.breakpoint.xs) ? 'text-center pl-2 pr-2' : 'text-center pl-10 pr-10'">
    <template>
      <v-card-title
        :class="($vuetify.breakpoint.xs) ? 'text-center fullwidth mt-5' : 'fullwidth mt-5'"
      >
          Sterowanie Pan z Pogodna
      </v-card-title>
    </template>
    <v-row 
      v-for="group in groups"
      :key="group.id"
      :name="group.id"
      outlined
      class="fullwidth pl-4 pr-4"
    >
      <v-col 
        cols="12"
      >
        <v-card-title primary-title class="pa-0">
          {{group.name}}
        </v-card-title>
      </v-col>
      <v-col
        v-for="button in group.buttons"
        :key="button.id"
        cols='6' xs='6' sm='4' md='4' lg='3' xl='4'
      >
          <v-card 
            :ref="button.name"
            outlined
            :class="($vuetify.breakpoint.xs) ? 'card' : 'card pl-3'"
            absolute
            
          > 
            <v-card-actions
              class="pt-0 pb-0"
            >
              <v-card-title primary-title class="pl-0">
                {{button.label}}
              </v-card-title>
              <v-spacer/>
              <v-progress-circular
                v-if="button.loading"
                indeterminate
                color="primary"
                size="200"
              ></v-progress-circular>
              <template
                v-else
              >
                <template
                  v-if="button.readable"
                >
                  <v-icon
                    v-if="button.active" 
                    x-large
                    color="yellow darken-1"
                  >
                    {{ button.iconActive }}
                  </v-icon>
                  <v-icon 
                    v-else
                    x-large
                    color="black"
                  >
                    {{ button.iconNonActive }}
                  </v-icon>
                </template>               
                <template
                  v-else
                >
                 <v-icon
                    x-large
                    color="black"
                    :class="button.class"
                  >
                    {{ button.iconActive }}
                  </v-icon>
                </template>
              </template>
            </v-card-actions>
            <v-card-actions
              class="mt-0"
            >
              <v-btn
                v-if="!button.loading"
                class="mb-2"
                dark
                x-normal
                @click="(button.confirmDialog) ? openConfirmationDialog(button.parameter, button): sendParamToRaspberry(button.parameter, button)"
                :class="($vuetify.breakpoint.xs) ? 'fullwidth' : ''"
              >
              {{ (!button.loading) ? (button.active) ? button.buttonLabelOff : button.buttonLabelOn : ''}}
              </v-btn>
              <v-btn
                v-if="button.loading"
                text
                disabled
                :class="($vuetify.breakpoint.xs) ? 'fullwidth' : ''"
              >
                <v-progress-linear
                  color="yellow darken-1"
                  indeterminate
                  rounded
                  height="3"
                ></v-progress-linear>
              </v-btn>
            </v-card-actions>
          </v-card> 
      </v-col>
    </v-row> 
    <v-snackbar
      v-model="snackbar"
      color="orange"
      content-class="text-center"
    >
        {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>
          mdi-close-thick
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      width="500"
    >
      <v-card>
        <v-card-title 
          :class="(darkMode) ? 'text-h5 secondary' : 'text-h5 primary'"
        >
          Potwierdzenie
        </v-card-title>
        <v-card-text
          class="mt-5"
        >
          Czy na pewno?
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Nie
          </v-btn>
           <v-btn
            color="secondary"
            text
            @click="confirm()"
          >
            Tak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  components: { 
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
    name: 'Steering',
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Pole wymagane',
        v => (v && v.length <= 32) || 'Name must be less than 32 characters',
      ],
      groups: [
        { 
          id: 1,
          name: 'Oświetlenie',
          buttons: [
            {id: 1, name: 'gardenLight1', label: 'Ogród 1', parameter: 2, active: null, loading: true, iconActive: 'mdi-lightbulb-outline', iconNonActive: 'mdi-lightbulb-off-outline', confirmDialog: false, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: ''},
            {id: 2, name: 'gardenLight2', label: 'Ogród 2', parameter: 3, active: null, loading: true, iconActive: 'mdi-lightbulb-outline', iconNonActive: 'mdi-lightbulb-off-outline', confirmDialog: false, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: '' },
            {id: 3, name: 'podjazdLight', label: 'Podjazd', parameter: 4, active: null, loading: true, iconActive: 'mdi-lightbulb-outline', iconNonActive: 'mdi-lightbulb-off-outline', confirmDialog: false, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: '' },
            {id: 4, name: 'flashLight', label: 'Flash', parameter: 11, loading: true, iconActive: 'mdi-lightbulb-outline', iconNonActive: 'mdi-lightbulb-off-outline', confirmDialog: false, buttonLabelOn: 'Start', buttonLabelOff: 'Start', readable:false, class: '' },
          ]
        },
        {
          id: 2,
          name: 'Bramy',
          buttons: [
            {id: 5, name: 'garageGate', label: 'Garaż', parameter: 5, active: null, loading: true, iconActive: 'mdi-garage-open-variant', iconNonActive: 'mdi-garage-variant', confirmDialog: true, buttonLabelOn: 'Otwórz', buttonLabelOff: 'Zamknij', readable:true, class: '' },
            {id: 6, name: 'wisniowskiGate', label: 'Wjazd', parameter: 6, active: null, loading: true, iconActive: 'mdi-boom-gate-alert', iconNonActive: 'mdi-boom-gate', confirmDialog: true, buttonLabelOn: 'Otwórz', buttonLabelOff: 'Zamknij', readable:true, class: '' },
            {id: 7, name: 'podworkoGate', label: 'Podwórko', parameter: 7, active: null, loading: true, iconActive: 'mdi-boom-gate', iconNonActive: 'mdi-boom-gate-alert', confirmDialog: true, buttonLabelOn: 'Ruch', buttonLabelOff: 'Ruch', readable:false, class: '' },
          ]
        },
        {
          id: 3,
          name: 'Alarm',
          buttons: [
            {id: 8, name: 'napad', label: 'Napad', parameter: 12, active: null, loading: true, iconActive: 'mdi-bullhorn-variant-outline', iconNonActive: 'mdi-bullhorn-variant-outline', confirmDialog: true, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: '' },
            {id: 9, name: 'resetalarm', label: 'Reset alarm', parameter: 13, active: null, loading: true, iconActive: 'mdi-close', iconNonActive: 'mdi-close', confirmDialog: false, buttonLabelOn: 'Start', buttonLabelOff: 'Start', readable:false, class: '' },
          ]
        },
        {
          id: 4,
          name: 'Ogrzewanie',
          buttons: [
            {id: 10, name: 'heating', label: 'Ogrzewanie', parameter: 8, active: null, loading: true, iconActive: 'mdi-bullhorn-variant-outline', iconNonActive: 'mdi-bullhorn-variant-outline', confirmDialog: false, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: '' },
            {id: 11, name: 'heating_manual', label: 'reczne', parameter: 8, active: null, loading: true, iconActive: 'mdi-bullhorn-variant-outline', iconNonActive: 'mdi-bullhorn-variant-outline', confirmDialog: false, buttonLabelOn: 'Włącz', buttonLabelOff: 'Wyłącz', readable:true, class: '' },
          ]
        },
        {
          id: 5,
          name: 'Ustawienia',
          buttons: [
            {id: 12, name: 'mute', label: 'Mute', parameter: 1, active: null, loading: true, iconActive: 'mdi-bell-off-outline', iconNonActive: 'mdi-bell-outline', confirmDialog: false,buttonLabelOn: 'Włącz dźwięk', buttonLabelOff: 'Wyłącz dźwięk', readable:true, class: '' },
          ]
        }
      ],
      snackbar: false,
      snackbarText: '',
      dialog: false,
      paramGlobal: null,
      buttonGlobal: null,
    };
  },
  watch: {
  },
  mounted() {
    const postData = {
      action: 'a',
      value: 0,
    }
    
    this.axios.post('/api/pzp/raspberry/action', postData)
    .then(( {status, data} ) => {
      if (status !== 200) {
        throw new Error('Wrong status code');
      }
      const res = data.data;
      Object.keys(res).forEach((el) => {
        this.groups.forEach((group) => {
          group.buttons.forEach((button) => {
            if (el === button.name) {
              if (button.name != 'heating') {
                button.active = data.data[el];
              } else {
                if( data.data[el] === 0){
                  button.buttonLabelOn = 'Automat';
                  button.value = 0;
                } else {
                  button.buttonLabelOn = 'Ręczne';
                  button.value = null;
                }
              }
            }
            button.loading = false;
          })
        });
      });
    });
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    sendParamToRaspberry(param, btn) {
      if (btn.name === 'heating') {
        if (btn.value === 0){
          this.groups.forEach((group) => {
            group.buttons.forEach((button) => {
              button.class = '';
              this.$refs.heating_manual.$el.class = 'hidden';
            });
          });
        }
        return;
      }
      btn.loading=true;
      const postData = {
        action: btn.name,
        value: param,
      }
      this.axios.post('/api/pzp/raspberry/action', postData)
      .then(( {status, data} ) => {
        if (status !== 200) {
          throw new Error('Wrong status code');
        }
        if (data.status == 'ok') {
          const res = data.data;
          Object.keys(res).forEach((el) => {
            this.groups.forEach((group) => {
              group.buttons.forEach((button) => {
                if (el === button.name) {
                  button.active = data.data[el];
                  button.loading = false;
                  if (el === 'podworkoGate' || el === 'flashLight' || el === 'resetalarm') {
                    button.class = 'blink';
                    setTimeout(function() {
                      group.buttons.forEach((button) => {
                        button.class = '';
                      });
                    }, 4000);
                  }
                }
              })
            });
          });
        } else {
          this.snackbar = true;
          this.snackbarText = data.status;
          this.groups.forEach((group) => {
            group.buttons.forEach((button) => {
              button.loading = false;
              if (data.data === button.name) {
                button.class = 'blink';
                setTimeout(function() {
                  group.buttons.forEach((button) => {
                    button.class = '';
                  });
                }, 4000);
              }
            });
          });
          btn.active = !btn.active;
        }
      });
    },
    openConfirmationDialog(param, btn){
      this.paramGlobal = param;
      this.buttonGlobal = btn;
      this.dialog = btn.confirmDialog;
    },
    confirm() {
      this.dialog = false;
      this.sendParamToRaspberry(this.paramGlobal, this.buttonGlobal);
    },
  },
}
</script>
<style>
.v-snack__content{
  color:#000;
}
.fullwidth{
  width: 100%;
}
.text-h5.primary{
  color:#fff;
}
.blink {
  animation: blink 0.5s 1000 alternate;
}
.black--text.blink {
  color: #efa11f !important;
}
@keyframes blink {
  from { opacity: 1; }
  to { opacity: 0; }
}
.hidden {
  display: none;
}
</style>