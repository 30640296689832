<template>
  <div :class="($vuetify.breakpoint.xs) ? 'text-center pl-2 pr-2' : 'text-center pl-10 pr-10'">
    <v-spacer />
    <v-row no-gutters>
      <v-img 
        src="/assets/icons/icon-72x72.png"
        max-height="30"
        max-width="30"
        class="mx-auto mt-3"
        v-if="$vuetify.breakpoint.xs"
      />
    </v-row>
    <div v-if="!loading">
      <template
          v-for="(chart) in data"
        >
          <h5
            :key="chart.title"
            v-if="chart.type === 'synchro' && !$vuetify.breakpoint.xs"
            align="right"
            class="pt-5 pb-6"
          >
            Ostatnia synchronizacja: {{chart.value}}
          </h5>
      </template>
      <v-row class="mt-1" no-gutters>
        <v-col
          v-for="(chart, index) in gauges"
          cols='6' xs='6' sm='6' md='2' lg='2' xl='2'
          :key="index"
        >
          <v-card
            v-if="!loading"
            outlined
            tile
            flat
            :class="(darkMode) ? 'card customBackground' : 'card'"
          >
            <v-chart ref="chart" :class="($vuetify.breakpoint.xs) ? 'chart-20' : 'chart-40'" :option="option" />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <template
          class="ma-0"
        >
          <h5
            class="text-center full-width mb-1"
          >
            Ciśnienie atmosferyczne
          </h5>
          <v-progress-linear
            class="ml-5 mr-5"
            v-model="pressurePercent"
            height="18"
            :rounded="true"
            color="amber"
          >
            <span class="fontWhite">{{ pressureValue }} hPa</span>
          </v-progress-linear>
        </template>
      </v-row>
      <v-row>
        <template
          v-for="(chart, index) in data"
          fullwidth
        >
        <v-col 
          cols='6' xs='3' sm='3' md='3' lg='3' xl='3'
          :key="index"
          v-if="!loading && chart.type === 'gate'"
          align="center"
        >
          <h5
            align="center"
            class="pt-5"
          >{{ chart.title }}</h5>
          <v-icon
            x-large
            color=""
            center
          >
            mdi-{{ (chart.value) ? chart.iconClosed : chart.iconOpen }}
          </v-icon>
          <h6
            align="center"
            class="pt-5"
          >{{ chart.date }}</h6>
        </v-col>
        </template>
      </v-row>
      <v-row>
        <v-btn
          icon
          color="amber"
          @click="getCurrentData"
          class="bottomSynchro mb-15"
        >
          <v-icon x-large>mdi-refresh</v-icon>
        </v-btn>
      </v-row>
      <template
        v-for="(chart) in data"
      >
        <h5
          :key="chart.title"
          v-if="chart.type === 'synchro' && $vuetify.breakpoint.xs"
          align="center"
          class="bottomSynchro mb-6"
        >
          Ostatnia synchronizacja: {{chart.value}}
        </h5>
      </template>
    </div>
    <div 
      class="text-center"
      v-if="loading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="200"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
  import { use } from "echarts/core";
  import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DataZoomComponent
  } from 'echarts/components';
  import { GaugeChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';
  import VChart from "vue-echarts";

  use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    GaugeChart,
    CanvasRenderer,
    UniversalTransition,
    DataZoomComponent
  ]);

  export default {
    components: {
      VChart,
    },
    props: {
      darkMode: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    name: 'Dashboard',
    data() {
      return {
        data: [],
        gauges: [],
        chartData: [],
        loading: false,
        option: {
          title: {
            text: '',
          },
          series: [],
        },
        pressurePercent: 0,
        pressureValue: 0,
      }
    },
    mounted() {
      this.getCurrentData();
    },
    beforeCreate(){
    },
    created(){
    },
    beforeMount(){
    },
    beforeUpdate(){
    },
    updated() {
      this.$nextTick(function () {
        if(typeof this.$refs.chart != 'undefined'){
          this.$refs.chart.forEach((chart, index) => {
            chart.setOption({
              series: this.chartData[index].series,
              title: {
                text: this.chartData[index].title, 
                x: 'center', 
                textStyle:{
                  fontSize: (!this.$vuetify.breakpoint.xs) ? 20 : 12,
                  color: (this.darkMode) ? '#fff' : '#000'
                }
              },
            });
          })
        }
      });
    },
    watch: {
    },
    methods: {
      getCurrentData() {
        this.data = [];
        this.gauges = [];
        this.chartData = [];
        const splitLineDistance = !this.$vuetify.breakpoint.xs ? -25 : -15;
        const axisLabelDistance = !this.$vuetify.breakpoint.xs ? -20 : -16;
        const detailFontSize = !this.$vuetify.breakpoint.xs ? 35 : 20;
        const axisTickDistance = !this.$vuetify.breakpoint.xs ? -17 : -11;
        const progressWidth = !this.$vuetify.breakpoint.xs ? 10 : 5;
        const axisLineWidth = !this.$vuetify.breakpoint.xs ? 10 : 5;
        const axisLabelFontSize = !this.$vuetify.breakpoint.xs ? 12 : 8;
        const axisTicksplitNumber =  !this.$vuetify.breakpoint.xs ? 5 : 5;
        const axisTickLineStyleWidth = !this.$vuetify.breakpoint.xs ? 2 : 1;
        const splitLineLineStyleWidth = !this.$vuetify.breakpoint.xs ? 2 : 2;
        const splitLineLength = !this.$vuetify.breakpoint.xs ? 14 : 10;
        this.loading = true;
        this.axios.get('/api/pzp/dashboard', [])
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.data = data;
          this.data.forEach((element, index) => {
            if (element.type === 'gauge') {
              const series = {
                type: 'gauge',
                center: ['50%', '50%'],
                startAngle: 200,
                endAngle: -20,
                min: (element.value < 0) ? element.min : 0,
                max: (element.value >= 0) ? element.max : 0,
                splitNumber: element.max / 4,
                itemStyle: {
                  color: element.color
                },
                progress: {
                  show: true,
                  width: progressWidth
                },
                pointer: {
                  show: false
                },
                axisLine: {
                  lineStyle: {
                    width: axisLineWidth,
                  },
                },
                axisTick: {
                  distance: axisTickDistance,
                  splitNumber: axisTicksplitNumber,
                  lineStyle: {
                    width: axisTickLineStyleWidth,
                    color: '#999'
                  }
                },
                splitLine: {
                  distance: splitLineDistance,
                  length: splitLineLength,
                  lineStyle: {
                    width: splitLineLineStyleWidth,
                    color: '#999'
                  }
                },
                axisLabel: {
                  distance: axisLabelDistance,
                  color: '#999',
                  fontSize: axisLabelFontSize
                },
                anchor: {
                  show: true
                },
                title: {
                  show: true
                },
                detail: {
                  valueAnimation: true,
                  lineHeight: 20,
                  borderRadius: index,
                  offsetCenter: [0, '-14%'],
                  fontSize: detailFontSize,
                  fontWeight: 'bolder',
                  formatter: '{value} °C',
                  color: 'inherit'
                },
                data: [
                  {
                    value: (element.value) ? element.value : 0
                  }
                ]
              };
              const title = element.title;
              this.chartData.push({'series' : series, 'title' : title});
              this.gauges.push(element);
            }
            if (element.type == 'pressure') {
              this.pressurePercent = Math.ceil((element.value - 980) / (1030 - 980) * 100);
              this.pressureValue = element.value;
            }
          });
          this.loading = false;
        });
      },
    },
  }
</script>
<style scoped>
.chart-40 {
  height: 40vh;
}
.chart-20 {
  height: 20vh;
  margin-top: 10px
}
.card {
  border: none;
}
.fontWhite {
  color: #fff;
  font-size: 85%;
}
.full-width {
  width: 100%;
  font-size: 12px;
}
.bottomSynchro {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.text-center {
  text-align: center;
}
.customBackground {
  background-color: #171917;
}
.fullwidth{
  width: 100%;
}
</style>
