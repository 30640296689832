<template>
  <v-row
    :class="
      $vuetify.breakpoint.xs
        ? 'text-center pl-2 pr-2'
        : 'text-center pl-10 pr-10'
    "
  >
    <template>
      <v-card-title>
      </v-card-title>
    </template>
    <v-row class="fullwidth pl-3 pr-3">
      No access
      {{this.$route.params.component}}
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "Expenses",
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarText: "",
      expenses: [],
      groups: [],
      group: null,
    };
  },
  watch: {},
  mounted() {
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>