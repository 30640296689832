var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',{class:_vm.$vuetify.breakpoint.xs
      ? 'text-center pl-2 pr-2'
      : 'text-center pl-10 pr-10',attrs:{"elevation":"0"}},[[_c('v-card-title',{class:_vm.$vuetify.breakpoint.xs
          ? 'text-center fullwidth mt-5 pl-0'
          : 'fullwidth mt-2 pl-0'},[_vm._v(" Grupy wydatków "),_c('v-spacer'),_c('v-card-subtitle',{staticClass:"pr-0",staticStyle:{"text-align":"right"}},[_vm._v(" data ważności modułu "),_c('br'),_c('b',[_vm._v(_vm._s(this.expireDate))])])],1),_c('v-card',{staticClass:"d-flex justify-end",attrs:{"elevation":"0"}},[_c('v-card-actions',[_c('v-btn',{staticClass:"secondary",attrs:{"dark":"","tile":"","to":"/expenses/group/add","loading":this.loading,"disabled":this.loading}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-note-plus ")]),_vm._v(" Dodaj grupę ")],1)],1)],1)],_c('v-card',[_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"items":_vm.groups,"disable-sort":true},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","to":("/expenses/group/" + (item.id) + "/edit")},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edycja")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":[function($event){return _vm.openDeleteModal(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_vm._v("Potwierdź usuwanie")])],1),_c('v-card-text',[_c('div',{staticClass:"text-p pa-12"},[_vm._v(" Czy usunąć grupę "),_c('b',[_vm._v(_vm._s(this.selectedGroup ? this.selectedGroup.name : ""))]),_vm._v(" z listy? ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"tile":"","color":"secondary"},on:{"click":function($event){return _vm.deleteGroup()}}},[_vm._v("Usuń")])],1)],1)]],2),_c('v-snackbar',{attrs:{"color":"orange"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"black","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)]}}],null,false,4083587044),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)],2):_c('div',{staticClass:"text-center mt-15 pt-15"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":200}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }