<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Lista urządzeń Callin
              <v-spacer />
              <v-btn
                dark
                tile
                to="/callin/devices/add"
              >
                <v-icon left>
                  mdi-note-plus
                </v-icon>
                Dodaj urządzenie
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="this.devices"
              class="elevation-1 ma-4 mt-10"
            >

              <template #[`item.type`]="{ item }">
                <span>
                  {{ item.deviceType.name}}
                </span>
              </template>
              <template #[`item.active`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="activateDevice(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="(item.active) ? 'green' : 'grey'"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ (item.active) ? 'wyłącz' : 'włącz' }}
                  </span>
                </v-tooltip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      link
                      :to="`/callin/device/${item.id}/edit`"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edycja</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="openDeleteModal(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Usuń</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-dialog
              transition="dialog-bottom-transition"
              v-model="deleteModal"
              max-width="600"
            >
              <template>
                <v-card>
                  <v-toolbar
                    color="secondary"
                  >Potwierdź usuwanie</v-toolbar>
                  <v-card-text>
                    <div class="text-p pa-12">
                      Czy usunąć urządzenie
                      <b>{{ (this.selectedDevice) ? this.selectedDevice.name : '' }}</b>
                      z listy?
                    </div>
                  </v-card-text>
                  <v-card-actions 
                    class="justify-end"
                  >
                    <v-btn
                      tile
                      @click="deleteModal = false"
                    >Anuluj</v-btn>
                    <v-btn
                      tile
                      color="secondary"
                      @click="deleteDevice()"
                    >Usuń</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <v-snackbar
              v-model="snackbar"
              color="orange"
            >
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>
                  mdi-close-thick
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </template>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Devices',
    data() {
      return {
        headers: [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Nazwa',
            value: 'name'
          },
          {
            text: 'Token',
            value: 'accessToken'
          },
          {
            text: 'PIN',
            value: 'pin'
          },
          {
            text: 'Typ',
            value: 'type'
          },         
          {
            text: 'Aktywny',
            value: 'active'
          },
          {
            text: 'Akcje',
            value: 'actions'
          },
        ],
        devices: [],
        snackbar: false,
        snackbarText: '',
        deleteModal: false,
        selectedDevice: null,
        loading: false,
      };
    },
    watch: {
    },
    mounted() {
      this.loading = true;
      this.fetchDevices();
    },
    methods: {
      fetchDevices() {
        this.axios.get('/api/callin/devices')
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.snackbar = true;
          this.snackbarText = 'Pobrano dane';
          this.devices = data;
          this.loading = false;
        });
      },
      deleteDevice() {
        const deviceId = this.selectedDevice.id;
        this.axios.delete(`/api/callin/device/${deviceId}`)
          .then(( {status} ) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.snackbar = true;
            this.snackbarText = 'Usunięto';
            this.fetchDevices();
            this.deleteModal = false;
        });
      },
      openDeleteModal(device) {
        this.deleteModal = true;
        this.selectedDevice = device;
      },
      activateDevice(item) {
        const postData = {
          active: (item.active === 1) ? 0 : 1,
        }
        this.axios.patch(`/api/callin/device/${item.id}/activate`, postData, { 'Content-Type': 'application/json' })
          .then(({ status }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.snackbar = true;
            this.snackbarText = 'Zapisano';
            this.fetchDevices();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  }
</script>
<style>
.v-snack__content{
  color:#000;
}
</style>