import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        themes: {
            dark: {
                primary: colors.grey,
                secondary: '#efa11f',
                background: '#171917',
            },
            light: {
                primary: '#000',
            },
        },
        options: {
            customProperties: true
        },
    },
});
