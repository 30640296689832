<template>
  <v-card
    :class="
      $vuetify.breakpoint.xs
        ? 'text-center pl-2 pr-2'
        : 'text-center pl-10 pr-10'
    "
    elevation="0"
    v-if="!loading"
  >
    <template>
      <v-card-title
        :class="
          $vuetify.breakpoint.xs
            ? 'text-center fullwidth mt-5 pl-0'
            : 'fullwidth mt-5 pl-0'
        "
      >
        {{this.componentTitle}}
        <v-spacer />
        <v-card-subtitle class="pr-0" style="text-align: right">
          data ważności modułu <br />
          <b>{{ this.expireDate }}</b>
        </v-card-subtitle>
      </v-card-title>
      <v-card class="d-flex justify-end" elevation="0">
        <v-card-actions>
          <v-btn
            color="secondary"
            tile
            @click="saveExpense"
          >
            <v-icon left> mdi-content-save-outline </v-icon>
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <v-card elevation="0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="expense.value"
          label="Wartość"
          required
          :rules="[(v) => !!v || 'Pole wymagane']"
          :disabled="this.loading"
          prepend-icon="mdi-cash-multiple"
        ></v-text-field>
        <v-select
          width="300"
          :items="groups"
          v-model="expense.group"
          label="Wybierz grupę"
          item-text="name"
          item-value="id"
          :rules="[(v) => !!v || 'Pole wymagane']"
          required
          class="mt-6"
          prepend-icon="mdi-ungroup"
        >
        </v-select>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="expense.date"
              label="Data wydatku"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="expense.date"
            no-title
            scrollable
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>
        <template v-if="isEdit">
          <h5 align="left" class="mt-4">
            data modyfikacji:
            {{ moment(expense.mtime).format("YYYY-MM-DD HH:mm:ss") }}
          </h5>
          <h5 align="left" class="mt-4">
            data utworzenia:
            {{ moment(expense.ctime).format("YYYY-MM-DD HH:mm:ss") }}
          </h5>
        </template>
      </v-form>
    </v-card>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="primary" :size="200" />
  </div>
</template>

<script>
export default {
  name: "ExpensesAddEdit",
  data() {
    return {
      expense: {
        value: "",
        group: null,
        date: this.moment(new Date()).format("YYYY-MM-DD"),
      },
      groups: [],
      snackbar: false,
      snackbarText: "",
      isEdit: false,
      valid: true,
      loading: false,
      nameRules: [
        (v) => !!v || "Name Pole wymagane",
        (v) => (v && v.length <= 32) || "Name must be less than 32 characters",
      ],
      menu: false,
      expireDate: "-",
      componentTitle: 'Dodaj wydatek'
    };
  },
  watch: {},
  mounted() {
    this.isEdit = this.checkIfEdit(this.$route.params.id);
    if (this.isEdit) {
      this.componentTitle = 'Edytuj wydatek';
      if (this.fetchGroups()) {
        this.fetchExpenseDetails(this.$route.params.id);
      }
    } else {
      this.fetchGroups();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    checkIfEdit(id) {
      if (id !== undefined) {
        return true;
      }
      return false;
    },

    fetchGroups() {
      this.loading = true;
      return new Promise((resolve) => {
        this.axios
          .get("/api/pzp/expenses-groups")
          .then(({ status, data }) => {
            if (status !== 200) {
              resolve(false);
              throw new Error("Wrong status code");
            }
            this.loading = false;
            this.groups = data.data;
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            console.log(err);
          });
      });
    },
    saveExpense() {
      this.loading = true;
      if (this.isEdit) {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .patch(`/api/pzp/expenses/${this.$route.params.id}`, this.expense, {
              "Content-Type": "application/json",
            })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/expenses");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      } else {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .post("/api/pzp/expenses", this.expense, {
              "Content-Type": "application/json",
            })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/expenses");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      }
    },
    fetchExpenseDetails(id) {
      this.loading = true;
      this.axios
        .get(`/api/pzp/expenses/${id}`, { "Content-Type": "application/json" })
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          if (!data) {
            this.$router.push("/expenses");
          }
          this.loading = false;
          this.expense = data.data;
          this.expense.date = this.moment(this.expense.date).format(
            "YYYY-MM-DD"
          );
          this.expireDate = data.expire_date;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>