<template>
  <v-card
    :class="
      $vuetify.breakpoint.xs
        ? 'text-center pl-2 pr-2'
        : 'text-center pl-10 pr-10'
    "
    elevation="0"
    v-if="!loading"
  >
    <template>
      <v-card-title
      :class="
        $vuetify.breakpoint.xs
          ? 'text-center fullwidth mt-5 pl-0'
          : 'fullwidth mt-5 pl-0'
      ">
        {{this.componentTitle}}
        <v-spacer />
        <v-card-subtitle class="pr-0" style="text-align: right">
          data ważności modułu <br />
          <b>{{ this.expireDate }}</b>
        </v-card-subtitle>
      </v-card-title>
    <v-card class="d-flex justify-end" elevation="0">
        <v-card-actions>
          <v-btn
          color="secondary"
          tile
          @click="savePassword"
          :loading="this.loading"
          :disabled="this.loading"
        >
          <v-icon left> mdi-content-save-outline </v-icon>
          Zapisz
        </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <v-card elevation="0">
        <v-form ref="form" v-model="valid" lazy-validation >
          <v-text-field
            v-model="password.name"
            label="Nazwa"
            required
            :rules="[(v) => !!v || 'Pole wymagane']"
            :disabled="this.loading"
            prepend-icon="mdi-format-text-variant"
          ></v-text-field>
          <v-text-field
            v-model="password.login"
            label="Login"
            required
            :rules="[(v) => !!v || 'Pole wymagane']"
            :disabled="this.loading"
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-text-field
            v-model="password.password"
            label="Hasło"
            required
            :rules="[(v) => !!v || 'Pole wymagane']"
            :disabled="this.loading"
            prepend-icon="mdi-lock-outline"
          ></v-text-field>
          <v-text-field
            v-model="password.host"
            label="Host"
            required
            :rules="[(v) => !!v || 'Pole wymagane']"
            :disabled="this.loading"
            prepend-icon="mdi-ip-outline"
          ></v-text-field>
          <v-text-field
            v-model="password.description"
            label="Opis"
            :disabled="this.loading"
            prepend-icon="mdi-card-text-outline"
          ></v-text-field>
          <v-select
            width="300"
            :items="groups"
            v-model="password.group"
            label="Wybierz grupę"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Pole wymagane']"
            required
            class="mt-6"
            prepend-icon="mdi-ungroup"
          >
          </v-select>
          <template v-if="isEdit">
            <h5 align="left" class="mt-4">
              data modyfikacji: {{ moment(password.mtime).format('YYYY-MM-DD HH:mm:ss') }}
            </h5>
            <h5 align="left" class="mt-4">
              data utworzenia: {{ moment(password.ctime).format('YYYY-MM-DD HH:mm:ss') }}
            </h5>
          </template>
      </v-form>
    </v-card>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="primary" :size="200" />
  </div>
</template>

<script>
export default {
  name: "PasswordsAddEdit",
  data() {
    return {
      password: {
        name: "",
        login: "",
        password: "",
        host: "",
        description: "",
        group: null,
        ctime: "",
        mtime: "",
      },
      groups: [],
      snackbar: false,
      snackbarText: "",
      isEdit: false,
      valid: true,
      loading: false,
      nameRules: [
        (v) => !!v || "Name Pole wymagane",
        (v) => (v && v.length <= 32) || "Name must be less than 32 characters",
      ],
      expireDate: "-",
      componentTitle: 'Dodaj hasło'
    };
  },
  watch: {},
  mounted() {
    this.isEdit = this.checkIfEdit(this.$route.params.id);
    if (this.isEdit) {
      this.componentTitle = 'Edytuj hasło';
      if (this.fetchGroups()) {
        this.fetchPasswordDetails(this.$route.params.id);
      }
    } else {
      this.fetchGroups();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    checkIfEdit(id) {
      if (id !== undefined) {
        return true;
      }
      return false;
    },
    fetchGroups() {
      this.loading = true;
      return new Promise((resolve) => {
        this.axios
          .get("/api/pzp/passwords-groups")
          .then(({ status, data }) => {
            if (status !== 200) {
              resolve(false);
              throw new Error("Wrong status code");
            }
            this.loading = false;
            this.groups = data.data;
            this.expireDate = data.expire_date;
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            console.log(err);
          });
      });
    },
    savePassword() {
      this.loading = true;
      if (this.isEdit) {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .patch(
              `/api/pzp/passwords/${this.$route.params.id}`,
              this.password,
              { "Content-Type": "application/json" }
            )
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/passwords");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      } else {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .post("/api/pzp/passwords", this.password, {
              "Content-Type": "application/json",
            })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/passwords");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      }
    },
    fetchPasswordDetails(id) {
      this.loading = true;
      this.axios
        .get(`/api/pzp/passwords/${id}`, { "Content-Type": "application/json" })
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.loading = false;
          this.password = data.data;
          this.expireDate = data.expire_date;
          this.password.group = data.passwordGroup.id;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>