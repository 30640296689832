<template>
  <v-container>
    <v-row class="text-center">
      <v-spacer />
      <v-col cols="12" sm="12" xs="12" md="2" right>
        <template>
          <v-card-title>
            <v-select
              width="300"
              :items="devices"
              v-model="device"
              label="Wybierz urządzenie"
              item-text="name"
              item-value="id"
              @change="changeDevice(device)"
              >
            </v-select>
          </v-card-title>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-chart ref="chart" class="chart" :option="option" />
    </v-row>
  </v-container>
</template>

<script>
  import { use } from "echarts/core";
  import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DataZoomComponent
  } from 'echarts/components';
  import { LineChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';
  import VChart from "vue-echarts";

  use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    DataZoomComponent
  ]);

  export default {
    components: {
      VChart,
    },
    name: 'Devices',
    data() {
      return {
        devices: [],
        device: null,
        option : {
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {},
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              dataView: { readOnly: false },
              magicType: { type: ['line', 'bar'] },
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} °C',
            },
          },
          dataZoom: [
            {
              type: 'inside',
              start: 70,
              end: 100
            },
            {
              start: 0,
              end: 100
            }
          ],
          series: [
            {
              name: 'Highest',
              type: 'line',
              smooth: true,
              data: [],
              itemStyle: {
                color: 'rgb(255, 70, 131)'
              },
              markPoint: {
                data: [
                  { type: 'max', name: 'Max' },
                  { type: 'min', name: 'Min' }
                ]
              },
              markLine: {
                data: [{ type: 'average', name: 'Avg' }]
              }
            },
          ]
        },
      };
    },
    mounted() {
      this.fetchDevices();
    },
    methods: {
      fetchDevices() {
        this.axios.get('/api/callin/devices')
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.devices = data;
          this.device = data[0];
          this.changeDevice(data[0].id);
        });
      },
      changeDevice(deviceId) {
        this.axios.get(`/api/callin/temperature/${deviceId}`)
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.fillDataForChart(data);
        });
      },
      fillDataForChart(data){
        let chartData = [];
        data.forEach((d) => {
          chartData.push([d.date.slice(0, 19).replace("T", " "), d.value]);
        });
        this.$refs.chart.setOption({
          series: [
            {
              data: chartData,
            }
          ]
        });
      },
    },
  }
</script>
<style scoped>
.chart {
  height: 60vh;
}
</style>
