var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',[(!_vm.loading)?_c('div',[[_c('v-card-title',[_vm._v(" Lista urządzeń Callin "),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","tile":"","to":"/callin/devices/add"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-note-plus ")]),_vm._v(" Dodaj urządzenie ")],1)],1),_c('v-data-table',{staticClass:"elevation-1 ma-4 mt-10",attrs:{"headers":_vm.headers,"items":this.devices},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.deviceType.name)+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.activateDevice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":(item.active) ? 'green' : 'grey'}},[_vm._v(" mdi-check-bold ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s((item.active) ? 'wyłącz' : 'włącz')+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","to":("/callin/device/" + (item.id) + "/edit")}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edycja")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Usuń")])])]}}],null,true)}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary"}},[_vm._v("Potwierdź usuwanie")]),_c('v-card-text',[_c('div',{staticClass:"text-p pa-12"},[_vm._v(" Czy usunąć urządzenie "),_c('b',[_vm._v(_vm._s((this.selectedDevice) ? this.selectedDevice.name : ''))]),_vm._v(" z listy? ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"tile":""},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"tile":"","color":"secondary"},on:{"click":function($event){return _vm.deleteDevice()}}},[_vm._v("Usuń")])],1)],1)]],2),_c('v-snackbar',{attrs:{"color":"orange"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"black","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)]}}],null,false,4083587044),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])]],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"200"}})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }