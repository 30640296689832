var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',[(!_vm.loading)?_c('div',[[_c('v-card-title',[_vm._v(" Lista aplikacji Callin "),_c('v-spacer')],1),_c('v-card',{staticClass:"d-flex justify-end",attrs:{"elevation":"0"}},[_c('v-col',{staticClass:"d-flex mt-0 pt-0",attrs:{"cols":"2","sm":"2"}},[_c('v-select',{staticClass:"mt-6",attrs:{"items":['nie', 'tak'],"label":"Filtruj stare tokeny","item-text":"name","item-value":"name","clearable":""},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 ma-4 mt-10",attrs:{"headers":_vm.headers,"items":this.applications},scopedSlots:_vm._u([{key:"item.ctime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.ctime).format('DD-MM-YYYY HH:mm:ss'))+" ")])]}},{key:"item.mtime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((item.mtime) ? _vm.moment(item.mtime).format('DD-MM-YYYY HH:mm:ss') : '')+" ")])]}},{key:"item.expiredToken",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((item.expiredToken) ? 'tak' : 'nie')+" ")])]}},{key:"item.firebaseToken",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.firebaseToken.substring(0,30)+'...'))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.firebaseToken))])])]}},{key:"item.notificationActive",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.activateNotfication(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":(item.notificationActive) ? 'green' : 'grey'}},[_vm._v(" mdi-check-bold ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s((item.notificationActive) ? 'wyłącz' : 'włącz')+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","to":("/callin/application/" + (item.id) + "/edit")}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edycja")])])]}}],null,true)}),_c('v-snackbar',{attrs:{"color":"orange"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"black","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-close-thick ")])],1)]}}],null,false,4083587044),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])]],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"200"}})],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }