<template>
  <v-card
    :class="
      $vuetify.breakpoint.xs ? 'text-center pl-2' : 'text-center pl-10 pr-10'
    "
    elevation="0"
    v-if="!loading"
  >
    <template>
      <v-card-title
        :class="
          $vuetify.breakpoint.xs
            ? 'text-center fullwidth mt-5 pl-0'
            : 'fullwidth mt-2 pl-0'
        "
      >
        Lista wydatków
        <v-spacer />
        <v-card-subtitle class="pr-0" style="text-align: right">
          data ważności modułu <br />
          <b>{{ this.expireDate }}</b>
        </v-card-subtitle>
      </v-card-title>
      <v-card class="d-flex justify-end" elevation="0">
        <v-col class="d-flex mt-0 pt-0" cols="2" sm="2">
          <v-select
            width="300"
            :items="groups"
            v-model="group"
            label="Wybierz grupę"
            item-text="name"
            item-value="name"
            clearable
            class="mt-6"
          >
          </v-select>
        </v-col>
        <v-card-actions>
          <v-btn
            dark
            tile
            to="/expenses/add"
            :loading="this.loading"
            :disabled="this.loading"
            class="secondary"
          >
            <v-icon left> mdi-note-plus </v-icon>
            Dodaj wydatek
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredData"
        :disable-sort="true"
        class="elevation-1 mt-5"
      >
        <template #[`item.date`]="{ item }">
          {{ moment(item.date).format("YYYY-MM-DD") }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                link
                :to="`/expenses/${item.id}/edit`"
                @click.stop.prevent
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edycja</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                @click="openDeleteModal(item)"
                @click.stop.prevent
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Usuń</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-snackbar v-model="snackbar" color="orange">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" icon v-bind="attrs" @click="snackbar = false">
            <v-icon> mdi-close-thick </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="primary" :size="200" />
  </div>
</template>

<script>
export default {
  name: "Expenses",
  data() {
    return {
      headers: [
        {
          text: "Wartość",
          value: "value",
          width: 300,
        },
        {
          text: "Data",
          value: "date",
          width: 300,
        },
        {
          text: "Grupa",
          value: "group",
          align: "right",
        },
        {
          text: "Akcje",
          value: "actions",
          align: "right",
          width: 200,
        },
      ],
      loading: false,
      snackbar: false,
      snackbarText: "",
      expenses: [],
      groups: [],
      group: null,
      expireDate: "-",
    };
  },
  watch: {},
  mounted() {
    if (this.fetchGroups()) {
      this.fetchData();
    }
  },
  computed: {
    filteredData() {
      if (this.group) {
        return this.expenses.filter((expense) => expense.group === this.group);
      } else {
        return this.expenses;
      }
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get("/api/pzp/expenses").then(({ status, data }) => {
        if (status !== 200) {
          throw new Error("Wrong status code");
        }
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = "Pobrano dane";
        this.expenses = data.data;
        this.expireDate = data.expire_date;
      });
    },
    openDeleteModal(password) {
      this.deleteModal = true;
      this.selectedPassword = password;
    },
    deletePassword() {
      const passwordId = this.selectedPassword.id;
      this.axios
        .delete(`/api/pzp/passwords/${passwordId}`)
        .then(({ status }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.deleteModal = false;
          this.snackbar = true;
          this.snackbarText = "Usunięto";
          this.fetchData();
        });
    },
    fetchGroups() {
      this.loading = true;
      return new Promise((resolve) => {
        this.axios
          .get("/api/pzp/expenses-groups")
          .then(({ status, data }) => {
            if (status !== 200) {
              resolve(false);
              throw new Error("Wrong status code");
            }
            this.groups = data.data;
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            console.log(err);
          });
      });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>