<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Szczegóły
              <v-spacer />
              <v-btn
                dark
                tile
                @click.native="$router.back()"
                class="mr-2"
              >
                <v-icon left>
                  mdi-chevron-left
                </v-icon>
                Wróć
              </v-btn>
              <v-btn
                color="secondary"
                tile
                @click="saveApplication"
              >
                <v-icon left>
                  mdi-content-save-outline
                </v-icon>
                Zapisz
              </v-btn>
            </v-card-title>
          </template>
          <template>
            <v-row>
              <v-col cols="6">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="container"
                >
                  <v-text-field
                    v-model="application.id"
                    label="Id"
                    :rules="[v => !!v || 'Pole wymagane']"
                    :disabled="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.ctime"
                    label="Data stworzenia"
                    :disabled="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.mtime"
                    label="Data modyfikacji"
                    :disabled="true"
                  ></v-text-field>
                  <v-checkbox
                    v-model="application.notificationActive"
                    label="Notyfikacje aktywne"
                  ></v-checkbox>
                  
                  <v-text-field
                    v-model="application.fingerprint"
                    label="Fingerprint"
                    :disabled="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.firebaseToken"
                    label="Token"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.os"
                    label="OS"
                    :disabled="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.model"
                    label="Model"
                    :disabled="true"
                  ></v-text-field>
                  <v-text-field
                    v-model="application.expiredToken"
                    label="Wygasły token"
                    :disabled="true"
                  ></v-text-field>
                   <v-card-subtitle class="pr-0 pl-0" style="text-align: left; font-weight: bold">
                      Wyślij pojedyńczą wiadomość <br />
                    </v-card-subtitle>
                  <v-row>
                    <v-col cols="3">
                      <v-text-field
                        v-model="singleNotificationTitle"
                        label="Tytuł"
                        :disabled=application.expiredToken
                      ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                      <v-text-field
                        v-model="singleNotificationContent"
                        label="Treść"
                        :disabled=application.expiredToken
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="secondary"
                        small
                        tile
                        class="mt-4"
                        @click="this.sendSingleNotification"
                        :disabled=application.expiredToken
                      >
                        Wyslij 
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-spacer/>
                
              </v-col>
                <v-col cols="6">
                  <v-card-title>
                    Połączone urządzenia
                  </v-card-title>
                  <v-data-table
                  :headers="headers"
                  :items="this.devices"
                  class="elevation-1 ma-4 mt-0"
                >
                  <template #[`item.active`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{  }">
                        
                          <v-icon
                            :color="(item.active) ? 'green' : 'grey'"
                          >
                            mdi-check-bold
                          </v-icon>
                        
                      </template>
                      <span>
                        {{ (item.active) ? 'wyłącz' : 'włącz' }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          link
                          :to="`/callin/device/${item.id}/edit`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edycja</span>
                    </v-tooltip>

                  </template>
                </v-data-table>
                <v-card-title>
                    Notyfikacje
                  </v-card-title>
                <v-data-table
                  :headers="headers2"
                  :items="this.notifications"
                  class="elevation-1 ma-4 mt-0"
                >
                  
                  <template #[`item.cdate`]="{ item }">
                    {{ parseTime(item.cdate)}}
                  </template>
                </v-data-table>
                <v-card-title>
                    Temperatury
                  </v-card-title>
                <v-data-table
                  :headers="headers3"
                  :items="this.temperatures"
                  class="elevation-1 ma-4 mt-0"
                >
                  
                  <template #[`item.date`]="{ item }">
                    {{ parseTime(item.date)}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ApplicationsAddEdit',
    data() {
      return {
        valid: true,
        nameRules: [
          v => !!v || 'Name Pole wymagane',
          v => (v && v.length > 32) || 'Name must be less than 32 characters',
        ],
        items: [],
        application: {
          id: '',
          ctime: '',
          mtime: '',
          fingerprint: '',
          firebaseToken: '',
          model: '',
          notificationActive: '',
          os: '',
          expiredToken: null,
        },
        isEdit: false,
        devices: [],
        notifications: [],
        temperatures: [],
        user: null,
        loading: false,
        singleNotificationTitle:'',
        singleNotificationContent:'',
        headers: [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Nazwa',
            value: 'name'
          },
          {
            text: 'Pin',
            value: 'pin'
          },
          {
            text: 'Typ',
            value: 'deviceType.name'
          },          
          {
            text: 'Aktywny',
            value: 'active'
          },
          {
            text: 'Akcje',
            value: 'actions'
          },
        ],
        headers2: [
          {
            text: 'Typ',
            value: 'type'
          },
          {
            text: 'Data',
            value: 'cdate'
          },
          {
            text: 'Tytuł',
            value: 'title'
          },
          {
            text: 'Treść',
            value: 'content'
          }
        ],
        headers3: [
          {
            text: 'Data',
            value: 'date'
          },
          {
            text: 'Temperatura',
            value: 'value'
          }
        ],
      };
    },
    async mounted() {
      this.loading = true;
      this.isEdit = this.checkIfEdit(this.$route.params.id);
      await this.fetchApplicationDetails(this.$route.params.id);
      
    },
    methods: {
      sendSingleNotification(){
         const postData = {
          'firebaseToken': this.application.firebaseToken,
          'title': this.singleNotificationTitle,
          'content': this.singleNotificationContent,
        }
        this.axios.post(`/api/callin/send-single-notification`, postData, { 'Content-Type': 'application/json' })
          .then(({ status, data }) => {
            if (status !== 200) {
              console.log(data);
              throw new Error('Wrong status code');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      parseTime(date){
        return this.moment(date).format('DD-MM-YYYY HH:mm:ss');
      },
      validate () {
        this.$refs.form.validate()
      },
      checkIfEdit(id) {
        if (id !== undefined) {
          return true;
        }
        return false;
      },
      async fetchApplicationDetails(id) {
        this.axios.get(`/api/callin/application/${id}`, { 'Content-Type': 'application/json' })
          .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.application = data.application;
            this.application.ctime = this.parseTime(data.ctime);
            this.application.mtime = this.parseTime(data.mtime);
            this.devices = data.devices;
            this.notifications = data.notifications;
            this.temperatures = data.temperatures;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      saveApplication () {
        if (this.isEdit) {
          this.axios.patch(`/api/callin/application/${this.$route.params.id}`, this.application, { 'Content-Type': 'application/json' })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error('Wrong status code');
              }
              this.$router.push('/callin/applications');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  }
</script>
