<template>
  <v-row :class="($vuetify.breakpoint.xs) ? 'text-center pl-2 pr-2' : 'text-center pl-10 pr-10'">
    <template>
      <v-card-title 
        :class="($vuetify.breakpoint.xs) ? 'text-center fullwidth mt-5' : 'fullwidth mt-5'"
      >
          Temperatury
      </v-card-title>
    </template>
    <v-row class="text-center fullwidth  pl-3 pr-3">
      <v-spacer />
      <v-col cols="12" sm="12" xs="12" md="8" >
        <v-select
          v-model="zones"
          :items="temperatureZones"
          item-text="value"
          item-value="id"
          label="Select"
          multiple
          chips
          persistent-hint
          deletable-chips
          prepend-icon="mdi-chart-bell-curve"
          @change="changeZone"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="4" >
         <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mt-3"
            v-model="date"
            label="Od dnia do dnia"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          range
          locale="pl-pl"
          @change="changeDate"
        ></v-date-picker>
      </v-menu>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="12" >
        <v-chart ref="chart" class="chart" :option="option" />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
  import { use } from "echarts/core";
  import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    DataZoomComponent
  } from 'echarts/components';
  import { LineChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { CanvasRenderer } from 'echarts/renderers';
  import VChart from "vue-echarts";

  use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    DataZoomComponent
  ]);

  export default {
    components: {
      VChart,
    },
    name: 'Devices',
    data() {
      return {
        dateMenu: false,
        date: [this.moment(new Date()).subtract(7,'d').format('YYYY-MM-DD'), this.moment(new Date()).format('YYYY-MM-DD')],
        selectedZones: [],
        temperatureZones: [],
        zones: [],
        chartData:[],
        option : {
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {},
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              magicType: { type: ['line', 'bar'] },
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} °C',
            },
          },
          
          dataZoom: [
            {
              type: 'inside',
              start: 70,
              end: 100
            },
            {
              start: 0,
              end: 100
            }
          ],
          series: this.chartData,
        },
      };
    },
    mounted() {
      this.getZones();
    },
    methods: {
      changeZone(value) {
        let added = value.filter(
          (val) => !this.selectedZones.includes(val)
        );
        let removed = this.selectedZones.filter(
          (val) => !value.includes(val)
        );
        this.selectedZones = value;
        if (added.length > 0 || value === null) {
          const postData = {
            dates: this.date,
            zones: [added[0]],
          }
          
          this.axios.post('/api/pzp/temperature', postData)
          .then(( {status, data} ) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            data.forEach((value) => {
              this.fillDataForChart(value, added[0]);
            });
          });
        } else {
          this.chartData[removed[0]] = [];
          this.fillDataForChart(null, removed[0]);
        }
      },
      fillDataForChart(data, index){
        let tempArr= [];
        if (data != null) {
          data.values.forEach((d) => {
            tempArr.push([d.data, d.value]);
          });
        }
        this.chartData[index] = {
          name: (data) ? data.details.shortname : '',
          type: 'line',
          smooth: true,
          itemStyle: {
            color: (data) ? `${data.details.color}` : null
          },
          markPoint: {
            data: [
              { type: 'max', name: 'Max' },
              { type: 'min', name: 'Min' }
            ]
          },
          markLine: {
            data: [{ type: 'average', name: 'Avg' }]
          },
          data: tempArr
        };
        this.$refs.chart.setOption({
          series: this.chartData,
        });
      },
      changeDate(){
        this.dateMenu = false;
        this.chartData.forEach((data, index) => {
          this.chartData[index] = [];
          this.fillDataForChart(null, index);
        });

        const postData = {
          dates: this.date,
          zones: this.selectedZones,
        }
        
        this.axios.post('/api/pzp/temperature', postData)
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          data.forEach((value, index) => {
            this.fillDataForChart(value, index);
          });
        });
      },
      getZones(){
        this.axios.get('/api/pzp/zones')
          .then(( {status, data} ) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            data.forEach((value) => {
              const zone = {id: value.chart_id, value: value.fullname};
              this.temperatureZones.push(zone);
            });
            this.changeZone([0]);
          });
      },
    },
  }
</script>
<style scoped>
.chart {
  height: 60vh;
}
.fullwidth{
  width: 100%;
}
</style>
