<template>
  <div>
    <v-card
      :class="
        $vuetify.breakpoint.xs ? 'text-center pl-2' : 'text-center pl-10 pr-10'
      "
      elevation="0"
      v-if="!loading"
    >
      <template>
        <v-card-title
          :class="
            $vuetify.breakpoint.xs
              ? 'text-center fullwidth mt-5 pl-0'
              : 'fullwidth mt-2 pl-0'
          "
        >
          Lista haseł
          <v-spacer />
          <v-card-subtitle class="pr-0" style="text-align: right">
            data ważności modułu <br />
            <b>{{ this.expireDate }}</b>
          </v-card-subtitle>
        </v-card-title>
        <v-card class="d-flex justify-end" elevation="0">
          <v-col class="d-flex mt-0 pt-0" cols="2" sm="2">
            <v-select
              :items="groups"
              v-model="group"
              label="Filtruj grupę"
              item-text="name"
              item-value="name"
              clearable
              class="mt-6"
            />
          </v-col>
          <v-card-actions>
            <v-btn
              dark
              tile
              to="/passwords/add"
              :loading="this.loading"
              :disabled="this.loading"
              class="secondary"
            >
              <v-icon left> mdi-note-plus </v-icon>
              Dodaj hasło
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="filteredData"
          :disable-sort="true"
          class="elevation-0 mt-5"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  link
                  :to="`/passwords/${item.id}/edit`"
                  @click.stop.prevent
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edycja</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="openDeleteModal(item)"
                  @click.stop.prevent
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Usuń</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="deleteModal"
          max-width="600"
        >
          <template>
            <v-card>
              <v-toolbar dark color="secondary">
                <v-toolbar-title>Potwierdź usuwanie</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div class="text-p pa-12">
                  Czy usunąć hasło
                  <b>{{
                    this.selectedPassword ? this.selectedPassword.name : ""
                  }}</b>
                  z listy?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn tile @click="deleteModal = false">Anuluj</v-btn>
                <v-btn tile color="secondary" @click="deletePassword()"
                  >Usuń</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog
          v-model="detailsModal"
          :fullscreen="this.$vuetify.breakpoint.xs ? true : false"
          :width="this.$vuetify.breakpoint.xs ? null : 600"
          :hide-overlay="this.$vuetify.breakpoint.xs ? true : false"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="secondary">
              <v-btn icon dark @click="detailsModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Szczegóły</v-toolbar-title>
            </v-toolbar>
            <v-list three-line subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Nazwa</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword ? this.selectedPassword.name : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Login</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword ? this.selectedPassword.login : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Hasło</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword ? this.selectedPassword.password : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Host</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword ? this.selectedPassword.host : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Grupa</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword ? this.selectedPassword.group : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Opis</v-list-item-title>
                  <v-list-item-subtitle>{{
                    this.selectedPassword
                      ? this.selectedPassword.description
                      : ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="orange">
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="black" icon v-bind="attrs" @click="snackbar = false">
              <v-icon> mdi-close-thick </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-card>
    <div class="text-center mt-15 pt-15" v-else>
      <v-progress-circular indeterminate color="primary" :size="200" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Passwords",
  data() {
    return {
      headers: [
        {
          text: "Nazwa",
          value: "name",
          width: 300,
        },
        {
          text: "Grupa",
          value: "group",
          align: "right",
        },
        {
          text: "Akcje",
          value: "actions",
          align: "right",
          width: 200,
        },
      ],
      loading: true,
      snackbar: false,
      snackbarText: "",
      passwords: [],
      deleteModal: false,
      selectedPassword: null,
      groups: [],
      group: null,
      detailsModal: false,
      expireDate: "-",
    };
  },
  watch: {},
  mounted() {
    if (this.fetchGroups()) {
      this.fetchData();
    }
  },
  computed: {
    filteredData() {
      if (this.group) {
        return this.passwords.filter(
          (password) => password.group === this.group
        );
      } else {
        return this.passwords;
      }
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get("/api/pzp/passwords").then(({ status, data }) => {
        if (status !== 200) {
          throw new Error("Wrong status code");
        }
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = "Pobrano dane";
        this.passwords = data.data;
        this.expireDate = data.expire_date;
      });
    },
    openDeleteModal(password) {
      this.deleteModal = true;
      this.selectedPassword = password;
    },
    deletePassword() {
      const passwordId = this.selectedPassword.id;
      this.axios
        .delete(`/api/pzp/passwords/${passwordId}`)
        .then(({ status }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.deleteModal = false;
          this.snackbar = true;
          this.snackbarText = "Usunięto";
          this.fetchData();
        });
    },
    fetchGroups() {
      this.loading = true;
      return new Promise((resolve) => {
        this.axios
          .get("/api/pzp/passwords-groups")
          .then(({ status, data }) => {
            if (status !== 200) {
              resolve(false);
              throw new Error("Wrong status code");
            }
            this.groups = data.data;
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
            console.log(err);
          });
      });
    },
    handleClick(value) {
      this.fetchPasswordDetails(value.id);
    },
    fetchPasswordDetails(id) {
      this.loading = true;
      this.axios
        .get(`/api/pzp/passwords/${id}`, { "Content-Type": "application/json" })
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.detailsModal = true;
          this.loading = false;
          this.selectedPassword = data;
          this.selectedPassword.group = data.passwordGroup.name;
          this.snackbar = true;
          this.snackbarText = "Pobrano dane";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
.v-list-item .v-list-item__title {
  padding-left: 10px;
}
</style>