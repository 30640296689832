<template>
  <v-row :class="($vuetify.breakpoint.xs) ? 'text-center pl-2 pr-2' : 'text-center pl-10 pr-10'">
    <template>
      <v-card-title
        :class="($vuetify.breakpoint.xs) ? 'text-center fullwidth mt-5' : 'fullwidth mt-5'"
      >
          Ustawienia Pan z Pogodna
        <v-spacer />
        <v-btn
          color="secondary"
          tile
          @click="saveSettings"
          :loading="this.loading"
          :disabled="this.loading"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          Zapisz
        </v-btn>
      </v-card-title>
    </template>
    <v-row 
      class="fullwidth pl-3 pr-3"
    >
      <v-col 
        cols="12"
      >
        <form
          ref="form"
          lazy-validation
        >
          <v-row class="mt-10">
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="settings.pzpIp"
                label="Adres IP Pzp"
                required
                :rules="[v => !!v || 'Pole wymagane']"
                :disabled="this.loading"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="settings.rainbirdIp"
                label="Adres IP Rainbird"
                required
                :rules="[v => !!v || 'Pole wymagane']"
                :disabled="this.loading"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="settings.raspberryIp"
                label="Adres IP Raspberry PI"
                required
                :rules="[v => !!v || 'Pole wymagane']"
                :disabled="this.loading"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="settings.raspberryAddress"
                label="Globalny adres HTTP Raspberry PI"
                required
                :rules="[v => !!v || 'Pole wymagane']"
                :disabled="this.loading"
              ></v-text-field>
            </v-col>

          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
  export default {
  components: { 
  },
    name: 'Settings',
    data() {
      return {
        valid: true,
        nameRules: [
          v => !!v || 'Pole wymagane',
          v => (v && v.length <= 32) || 'Name must be less than 32 characters',
        ],
        loading: false,
        settings: {
          pzpIp: '',
          raspberryIp: '',
          raspberryAddress: '',
          rainbirdIp: '',
        }
      };
    },
    watch: {
    },
    mounted() {
      this.fetchSettings();
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      fetchSettings() {
        this.loading = true;
        this.axios.get('/api/pzp/settings')
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.loading = false;
          this.settings.pzpIp = data.pzpIp;
          this.settings.raspberryIp = data.raspberryIp;
          this.settings.raspberryAddress = data.raspberryAddress;
          this.settings.rainbirdIp = data.rainbirdIp;
        });
      },
      saveSettings() {
        this.loading = true;
        const saveData = this.settings;
        this.axios.post('/api/pzp/settings', saveData)
          .then(( {status } ) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.loading = false;
          });
      },
    },
  }
</script>
<style>
.v-snack__content{
  color:#000;
}
.fullwidth{
  width: 100%;
}
</style>