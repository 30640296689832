<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Szczegóły
              <v-spacer />
              <v-btn
                dark
                tile
                @click.native="$router.back()"
                class="mr-2"
              >
                <v-icon left>
                  mdi-chevron-left
                </v-icon>
                Wróć
              </v-btn>
              <v-btn
                color="secondary"
                tile
                @click="saveDevice"
              >
                <v-icon left>
                  mdi-content-save-outline
                </v-icon>
                Zapisz
              </v-btn>
            </v-card-title>
          </template>
          <template>
            <v-row>
              <v-col cols="4">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="container"
                >
                  <v-text-field
                    v-model="device.name"
                    label="Nazwa"
                    :rules="[v => !!v || 'Pole wymagane']"
                  ></v-text-field>
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="items"
                    v-model="device.type"
                    label="Typ/rodzaj"
                    required
                    :rules="[v => !!v || 'Pole wymagane']"
                    >
                  </v-select>
                  <v-text-field
                    v-model="device.password"
                    label="Hasło"
                  ></v-text-field>
                  <v-text-field
                    v-model="device.description"
                    label="Opis"
                  ></v-text-field>
                  <v-checkbox
                    v-model="device.active"
                    label="Produkt aktywny"
                  ></v-checkbox>
                  <v-select
                    width="300"
                    :items="users"
                    v-model="user"
                    label="Wybierz użytkownika"
                    item-text="username"
                    item-value="id"
                    required
                    >
                  </v-select>
                  <v-text-field
                    v-model="device.accessToken"
                    label="Token"
                  ></v-text-field>
                  <v-text-field
                    v-model="device.pin"
                    label="PIN"
                  ></v-text-field>
                  <v-text-field
                    v-model="device.ip"
                    label="IP"
                    :disabled="true"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="8">
                <v-data-table
                  :headers="headers"
                  :items="this.applications"
                  class="elevation-1 ma-4 mt-10"
                >
                  <template #[`item.ctime`]="{ item }">
                    <span>
                      {{ moment(item.ctime).format('DD-MM-YYYY HH:mm:ss')}}
                    </span>
                  </template>
                  <template #[`item.mtime`]="{ item }">
                    <span>
                      {{ (item.mtime) ? moment(item.mtime).format('DD-MM-YYYY HH:mm:ss') : ''}}
                    </span>
                  </template>
                  <template #[`item.firebaseToken`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        > {{item.firebaseToken.substring(0,30)+'...'}}</span>
                      </template>
                      <span> {{item.firebaseToken}}</span>
                    </v-tooltip>
                  </template>
                  <template #[`item.notificationActive`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ }">
                       
                          <v-icon
                            :color="(item.notificationActive) ? 'green' : 'grey'"
                          >
                            mdi-check-bold
                          </v-icon>
                        
                      </template>
                      <span>
                        {{ (item.notificationActive) ? 'wyłącz' : 'włącz' }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          link
                          :to="`/callin/application/${item.id}/edit`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edycja</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DeviceAddEdit',
    data() {
      return {
        valid: true,
        nameRules: [
          v => !!v || 'Name Pole wymagane',
          v => (v && v.length > 32) || 'Name must be less than 32 characters',
        ],
        items: [],
        device: {
          name: '',
          type: '',
          description: '',
          password: '',
          active: true,
          user: null,
          accessToken: '',
          pin: '',
          ip: '',
        },
        headers: [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Data stworzenia',
            value: 'ctime'
          },
          {
            text: 'Data modyfikcaji',
            value: 'mtime'
          },
          {
            text: 'Model',
            value: 'model'
          },
          {
            text: 'wygasły token',
            value: 'expiredToken'
          },
          {
            text: 'Token',
            value: 'firebaseToken',
            width: '20%'
          },
          {
            text: 'OS',
            value: 'os'
            },          
          {
            text: 'Aktywne notyfikcje',
            value: 'notificationActive'
          },
          {
            text: 'Akcje',
            value: 'actions'
          },
        ],
        applications: [],
        isEdit: false,
        users: [],
        user: null,
        loading: false,
      };
    },
    async mounted() {
      this.loading = true;
      this.isEdit = this.checkIfEdit(this.$route.params.id);
      await this.fetchUsers();
      await this.fetchDeviceTypes();
      if (this.isEdit) {
        await this.fetchDeviceDetails(this.$route.params.id);
      } else {
        this.device.accessToken = this.generateString(10);    
        this.device.pin = this.generatePin(6);    
      }
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      checkIfEdit(id) {
        if (id !== undefined) {
          return true;
        }
        return false;
      },
      saveDevice () {
        this.device.user = this.user;
        if (this.isEdit) {
          this.axios.patch(`/api/callin/device/${this.$route.params.id}`, this.device, { 'Content-Type': 'application/json' })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error('Wrong status code');
              }
              this.$router.push('/callin/devices');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.axios.post('/api/callin/device', this.device, { 'Content-Type': 'application/json' })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error('Wrong status code');
              }
              this.$router.push('/callin/devices');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      async fetchDeviceDetails(id) {
        this.axios.get(`/api/callin/device/${id}`, { 'Content-Type': 'application/json' })
          .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.device = data.device;
            this.device.type = data.device.deviceType.id;
            this.applications = data.applications;
            this.user = data.user;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async fetchDeviceTypes() {
        this.axios.get('/api/callin/device-types')
        .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            if (data !== null) {
              this.items = data;
            } else {
              this.storeLogout();
            }
            this.loading = false;
        });
      },
      async fetchUsers() {
        this.axios.get('/api/users')
        .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            if (data !== null) {
              this.users = data;
            } else {
              this.storeLogout();
            }
        });
      },
      generateString(length) {
        const characters ='abcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      generatePin(length) {
        const characters ='0123456789';
        let result = '';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
    },
  }
</script>
