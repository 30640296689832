<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    class="border"
  >
    <v-list
        dense
        nav
        class="pt-0 pr-0 pl-0"
      >
        <v-list-item two-line 
          :class="(darkMode) ? 'mb-0 customGrayBgDark' : 'mb-0 customGrayBg'"
        >
          <v-list-item-avatar class="mt-3" height="37" width="37">
            <v-avatar
              color="secondary"
              size="56"
            >
            {{ user.firstName.charAt(0).toUpperCase() }}{{ user.lastName.charAt(0).toUpperCase() }}
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content class="pt-0 pb-0">
            <v-list-item-title class="pl-0">{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
            <v-list-item-subtitle>
              Zalogowano<br/>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <template
          v-for="item in menuItems"
        >
          <v-subheader
            :key="item.name"
          >
            {{item.name}}</v-subheader>
          <v-list-group no-action 
            v-for="item in item.components"
            :key="item.id"
            :value="true"
            :append-icon="(item.subComponents.length) ? '$expand' : null"
            :class="(item.subComponents.length) ? 'hasChildren' : 'noChildren'"
            >
            <v-list-item
              :to="(item.subComponents.length) ? null : item.to"
              class="ml-0 mb-0 pl-0 item" 
              slot='activator'
            >
              <v-list-item-icon
                class="mr-3 ml-3"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item 
              v-for='sub in item.subComponents' 
              :key="sub.name"
              :to="sub.link"
              class="pl-10 child item"
            >
              <v-list-item-icon
                class="mr-3"
              >
                <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sub.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions} from 'vuex';
  export default {
    props: {
      darkMode: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    data() {
      return {
        drawer: !this.$vuetify.breakpoint.xs,
        menuItems: [
        ],
      };
    },
    computed: {
      ...mapGetters({
        components: 'auth/components',
        user: 'auth/user',
      }),
    },
    watch: {
      components(value){
        if (value) {
          console.log('ad');
          console.log(value);
        }else{
          console.log('chuj');
        }
      }
    },
    mounted() {
      if (this.components.length === 0) {
        this.storeLogout();
      } else{
        this.createMainMenu();      
      }
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
      },
      createMainMenu() {
        this.components.forEach((component, index) => {
          component.id = index;
          this.menuItems.push(component);
        });
      },
      ...mapActions({
        storeLogout: 'auth/logout',
      }),
    },
  };
</script>
    
<style>
.customGrayBg {
  background-color:#f5f5f5;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 0 !important;
}
.customGrayBgDark{
  background-color:#272727;
  border: none !important;
  border-radius:0 !important;
}
.border{
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-radius:0;
}
.v-list-item--link:before{
    background-color: #bbb;
    border-radius: 0;
}
.v-list-item--link{
  padding: 0 !important;
}
.child:hover{
  background-color: #f8f8f8;
}
.hasChildren a.v-list-item--link:before{
  background: none !important;
  background-color: #fff !important;
}
.item{
  opacity: 0.87
}
.v-list-group__items a.v-list-item--active{
  background-color: #f5f5f5;
  border-radius: 0;
}
</style>