<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Szczegóły użytkownika
              <v-spacer />
              <v-btn
                dark
                tile
                @click.native="$router.back()"
                class="mr-2"
              >
                <v-icon left>
                  mdi-chevron-left
                </v-icon>
                Wróć
              </v-btn>
              <v-btn
                color="secondary"
                tile
                @click="saveUser"
              >
                <v-icon left>
                  mdi-content-save-outline
                </v-icon>
                Zapisz
              </v-btn>
            </v-card-title>
          </template>
          <v-row>
            <v-col cols="6">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pa-4"
              >
                <v-text-field
                  v-model="user.username"
                  :rules="nameRules"
                  label="Nazwa użytkownika"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="user.userDetails.firstName"
                  :rules="nameRules"
                  label="Imię"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="user.userDetails.lastName"
                  :rules="nameRules"
                  label="Nazwisko"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="user.email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="!isEdit"
                  v-model="user.password"
                  :rules="passwordRules"
                  label="Hasło"
                  required
                ></v-text-field>
                <v-select
                  :items="roles"
                  v-model="user.roles"
                  label="Rola"
                  required
                  :rules="[v => !!v || 'Pole wymagane']"
                  >
                </v-select>
                <template v-if="isEdit">
                  <h5 align="left" class="mt-4">
                    data utworzenia: {{ moment(user.userDetails.cdate).format('YYYY-MM-DD HH:mm:ss') }}
                  </h5>
                  <h5 align="left" class="mt-4">
                    ostatnie logowanie: {{ moment(user.userDetails.lastLogin).format('YYYY-MM-DD HH:mm:ss') }}
                  </h5>
              </template>
              </v-form>
              <template v-if="this.user.roles == 'CALLIN_USER'">
                <v-card-title>
                  Urządzenia użytkownika
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="this.user.devices"
                  class="elevation-1 ma-4"
                >
                  <template #[`item.active`]="{ item }">
                      <template>
                        <v-icon
                          :color="(item.active) ? 'green' : 'grey'"
                        >
                          mdi-check-bold
                        </v-icon>
                      </template>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          link
                          :to="`/callin/device/${item.id}/edit`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edycja</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </template>
            </v-col>
            <v-col cols="6">
              <template>
                <v-card-title>
                  Dostępy użytkownika
                </v-card-title>
                <v-treeview
                  item-disabled="locked"
                  selectable
                  v-model="selection"
                  :items="sections"
                  item-children="components"
                  item-text="title"
                  @input="onUpdate"
                  :open-on-click="true"
                  open-all
                  selection-type="independent"
                >
                  <template v-slot:append="{item}">
                    {{item.expire_date}}
                  </template>
                  <template v-slot:label="{item}">
                    <div class="v-treeview-node__label">{{item.name}}</div>
                  </template>
                </v-treeview>
              </template>
            </v-col>
            <v-snackbar
              v-model="snackbar"
              color="orange"
            >
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>
                  mdi-close-thick
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-row>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  export default {
    name: 'UserAdd',
    data() {
      return {
        headers: [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Nazwa',
            value: 'name'
          },
          {
            text: 'Hasło',
            value: 'password'
          },
          {
            text: 'Typ',
            value: 'type'
          },
          {
            text: 'Opis',
            value: 'description'
            },          
          {
            text: 'Aktywny',
            value: 'active'
          },
          {
            text: 'Akcje',
            value: 'actions'
          },
        ],
        valid: true,
        nameRules: [
          v => !!v || 'Name Pole wymagane',
          v => (v && v.length <= 32) || 'Name must be less than 32 characters',
        ],
        emailRules: [
          v => !!v || 'Email Pole wymagane',
          v => (v && v.length <= 32) || 'Email must be less than 32 characters',
        ],
        passwordRules: [
          v => !!v || 'Password Pole wymagane',
          v => (v && v.length <= 32) || 'Password must be less than 32 characters',
        ],
        user: {
          username: '',
          email: '',
          password: '',
          roles: '',
          userDetails: {
            firstName: '',
            lastName: '',
            cdate: '',
            lastLogin: '',
          }
        },
        sections: [],
        userComponents: [],
        userSections: [],
        roles: [
          'ADMIN',
          'PZP_USER',
          'CALLIN_USER',
        ],
        isEdit: false,
        selection: [],
        loading: false,
        snackbar: false,
        snackbarText: '',
      };
    },
    mounted() {
      this.isEdit = this.checkIfEdit(this.$route.params.id);
      if (this.isEdit) {
          this.fetchUserDetails(this.$route.params.id);
      }
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      checkIfEdit(id) {
        if (id !== undefined) {
          return true;
        }
        return false;
      },
      saveUser () {
        if (this.isEdit) {
          this.loading = true;
          this.user.roles = [this.user.roles];
          this.axios.patch(`/api/user/${this.$route.params.id}`, this.user, { 'Content-Type': 'application/json' })
            .then(() => {
              this.loading = false;
              this.$router.push('/users');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.axios.post('/register', this.user, { 'Content-Type': 'application/json' })
            .then(() => {
              this.loading = false;
              this.$router.push('/users');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      fetchUserDetails(id) {
        this.loading = true;
        this.axios.get(`/api/user/${id}`, { 'Content-Type': 'application/json' })
          .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.loading = false;
            this.user = data;
            this.user.roles = data.roles[0];
            this.fetchSections(this.user.id);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      fetchSections(userId) {
        this.loading = true;
        const postData = {
          'user': userId,
        };
        this.axios.post(`/api/pzp/user-sections`, postData, { 'Content-Type': 'application/json' })
          .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            console.log(data);
            data.forEach((element, index) => {
              element.title = element.name
              element.components.forEach((component) => {
                component.id = component.id + (index*100) + 100;
                component.name = component.title;
                if(component.owned){
                  this.selection.push(component.id);
                }
              });
              if(element.owned){
                 this.selection.push(element.id);
              }
            });
            this.sections = data;
            this.loading = false;
            this.snackbar = true;
            this.snackbarText = 'Pobrano dane';
            console.log('loaded');
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onUpdate() {
        if (!this.__initial) {
          this.__initial = true
          return
        }
        if(this.manageAdminUser()) {
          return;
        }
        this.userSections = [];
        this.userComponents = [];
        this.selection.forEach((element) => {
          if (!Number.isNaN(element)) {
            if (String(element).length == 1) {
              const sections = String(element);
              this.userSections = [...new Set([...this.userSections, Number(sections)])];
            }
            const components = String(element)[1] + String(element)[2];
            this.userComponents = [...new Set([...this.userComponents, Number(components)])];
          }
        });
        this.saveSections();

      },
      saveSections() {
        if (!this.user.id){
          return;
        }
        this.loading = true;
        this.userSections = this.userSections.filter(function (value) {
            return !Number.isNaN(value);
        })
        this.userComponents = this.userComponents.filter(function (value) {
            return !Number.isNaN(value);
        })
        const postData = {
          'sections': this.userSections,
          'components': this.userComponents,
          'user': this.user.id,
        }
        this.axios.post('/api/pzp/sections', postData, { 'Content-Type': 'application/json' })
          .then(() => {
            this.snackbar = true;
            this.snackbarText = 'Zapisano';
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      manageAdminUser() {
        if(this.user.email == 'marek.spychalski@gmail.com'){
          if (!this.selection.includes(3)){
            this.selection.push(3);
            return true
          }
          if (!this.selection.includes(309)){
            this.selection.push(309);
            return true
          }
        }
      }
    },
  }
</script>
<style>
.v-treeview-node__label{
  text-align: left !important;
}
</style>