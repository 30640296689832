var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',{class:_vm.$vuetify.breakpoint.xs
      ? 'text-center pl-2 pr-2'
      : 'text-center pl-10 pr-10',attrs:{"elevation":"0"}},[[_c('v-card-title',{class:_vm.$vuetify.breakpoint.xs
          ? 'text-center fullwidth mt-5 pl-0'
          : 'fullwidth mt-5 pl-0'},[_vm._v(" "+_vm._s(this.componentTitle)+" "),_c('v-spacer'),_c('v-card-subtitle',{staticClass:"pr-0",staticStyle:{"text-align":"right"}},[_vm._v(" data ważności modułu "),_c('br'),_c('b',[_vm._v(_vm._s(this.expireDate))])])],1),_c('v-card',{staticClass:"d-flex justify-end",attrs:{"elevation":"0"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","tile":""},on:{"click":_vm.saveExpense}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save-outline ")]),_vm._v(" Zapisz ")],1)],1)],1)],_c('v-card',{attrs:{"elevation":"0"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Wartość","required":"","rules":[function (v) { return !!v || 'Pole wymagane'; }],"disabled":this.loading,"prepend-icon":"mdi-cash-multiple"},model:{value:(_vm.expense.value),callback:function ($$v) {_vm.$set(_vm.expense, "value", $$v)},expression:"expense.value"}}),_c('v-select',{staticClass:"mt-6",attrs:{"width":"300","items":_vm.groups,"label":"Wybierz grupę","item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Pole wymagane'; }],"required":"","prepend-icon":"mdi-ungroup"},model:{value:(_vm.expense.group),callback:function ($$v) {_vm.$set(_vm.expense, "group", $$v)},expression:"expense.group"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data wydatku","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.expense.date),callback:function ($$v) {_vm.$set(_vm.expense, "date", $$v)},expression:"expense.date"}},'v-text-field',attrs,false),on))]}}],null,false,2612417753),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.expense.date),callback:function ($$v) {_vm.$set(_vm.expense, "date", $$v)},expression:"expense.date"}})],1),(_vm.isEdit)?[_c('h5',{staticClass:"mt-4",attrs:{"align":"left"}},[_vm._v(" data modyfikacji: "+_vm._s(_vm.moment(_vm.expense.mtime).format("YYYY-MM-DD HH:mm:ss"))+" ")]),_c('h5',{staticClass:"mt-4",attrs:{"align":"left"}},[_vm._v(" data utworzenia: "+_vm._s(_vm.moment(_vm.expense.ctime).format("YYYY-MM-DD HH:mm:ss"))+" ")])]:_vm._e()],2)],1)],2):_c('div',{staticClass:"text-center mt-15 pt-15"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":200}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }