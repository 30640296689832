import Vue from 'vue';
import axios from 'axios';
import store from '../store';
import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = 'https://api.panzpogodna.pl';
//axios.defaults.baseURL = 'https://127.0.0.1:3500';
const config = {
  headers: {},
};

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create(config);

_axios.interceptors.request.use(
  // eslint-disable-next-line no-shadow
  (config) => {
    const accessToken = store.state.auth.token || localStorage.getItem('token') || null;
    if (accessToken !== null) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
  ,
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    // Do something with response error
    if (error.response.status === 401 && !error.config.url.includes('token')) {
      await store.dispatch('auth/refresh');
      const newToken = store.getters['auth/token'];
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${newToken}`;
      return new _axios(error.config);
    }
    if (error.response.status === 403) {
      router.push(`${'/no-access'}/${error.response.data.errors.split(':')[1]}`);
    }
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(error);
  },
);

// eslint-disable-next-line no-shadow
Plugin.install = function install(Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
