import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import TemperaturesCallin from '../views/callin/Temperatures.vue';
import DevicesCallin from '../views/callin/Devices.vue';
import DeviceAddEditCallin from '../views/callin/DeviceAddEdit.vue';
import Users from '../views/pzp/Users.vue';
import UserAddEdit from '../views/pzp/UserAddEdit.vue';
import DashboardPzp from '../views/pzp/Dashboard.vue';
import TemperaturesPzp from '../views/pzp/Temperatures.vue';
import SettingsPzp from '../views/pzp/Settings.vue';
import SteeringPzp from '../views/pzp/Steering.vue';
import PasswordsPzp from '../views/pzp/Passwords.vue';
import PasswordsAddEditPzp from '../views/pzp/PasswordsAddEdit.vue';
import PasswordsGroupsPzp from '../views/pzp/PasswordsGroups.vue';
import PasswordsGroupsAddEditPzp from '../views/pzp/PasswordsGroupsAddEdit.vue';
import ExpensesPzp from '../views/pzp/Expenses.vue';
import ExpensesGroupsPzp from '../views/pzp/ExpensesGroups.vue';
import ExpensesGroupsAddEditPzp from '../views/pzp/ExpensesGroupsAddEdit.vue';
import ExpensesAddEditPzp from '../views/pzp/ExpensesAddEdit.vue';
import NoAccess from '../views/NoAccess.vue';
import Applications from '../views/callin/Applications.vue';
import ApplicationAddEditCallin from '../views/callin/ApplicationAddEdit.vue';
4
Vue.use(VueRouter);

const routes = [
  {
    path: '/callin/temperatures',
    name: 'TemperaturesCallin',
    component: TemperaturesCallin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/callin/devices',
    name: 'devices',
    component: DevicesCallin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/callin/devices/add',
    name: 'deviceAdd',
    component: DeviceAddEditCallin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/callin/applications',
    name: 'Applications',
    component: Applications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/callin/application/:id/edit',
    name: 'ApplicationEdit',
    component: ApplicationAddEditCallin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/callin/device/:id/edit',
    name: 'DeviceEdit',
    component: DeviceAddEditCallin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/add',
    name: 'userAdd',
    component: UserAddEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/:id/edit',
    name: 'UserEdit',
    component: UserAddEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'DashboardPzp',
    component: DashboardPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/temperatures',
    name: 'TemperaturesPzp',
    component: TemperaturesPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords',
    name: 'PasswordsPzpList',
    component: PasswordsPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords/:id/edit',
    name: 'PasswordsAddEditPzpEdit',
    component: PasswordsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords/add',
    name: 'PasswordsAddEditPzpAdd',
    component: PasswordsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords/groups',
    name: 'PasswordsGroupsAddEditPzpList',
    component: PasswordsGroupsPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords/group/:id/edit',
    name: 'PasswordsGroupsAddEditPzpEdit',
    component: PasswordsGroupsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/passwords/group/add',
    name: 'PasswordsGroupsAddEditPzpAdd',
    component: PasswordsGroupsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses',
    name: 'expensesPzpList',
    component: ExpensesPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses/groups',
    name: 'ExpensesGroupsPzpList',
    component: ExpensesGroupsPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses/group/add',
    name: 'ExpensesGroupsPzpAdd',
    component: ExpensesGroupsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses/group/:id/edit',
    name: 'ExpensesGroupsPzpEdit',
    component: ExpensesGroupsAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses/add',
    name: 'ExpensesPzpAdd',
    component: ExpensesAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expenses/:id/edit',
    name: 'ExpensesPzpEdit',
    component: ExpensesAddEditPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'SettingsPzp',
    component: SettingsPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/steering',
    name: 'SteeringPzp',
    component: SteeringPzp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/no-access/:component',
    name: 'NoAccess',
    component: NoAccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    name: 'Login2',
    component: () => import('../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
