<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <div v-if="!loading">
          <template>
            <v-card-title>
              Lista aplikacji Callin
              <v-spacer />
            </v-card-title>
            <v-card class="d-flex justify-end" elevation="0">
          <v-col class="d-flex mt-0 pt-0" cols="2" sm="2">
            <v-select
              :items="['nie', 'tak']"
              v-model="group"
              label="Filtruj stare tokeny"
              item-text="name"
              item-value="name"
              clearable
              class="mt-6"
            />
          </v-col>
        </v-card>
            <v-data-table
              :headers="headers"
              :items="this.applications"
              class="elevation-1 ma-4 mt-10"
            >
              <template #[`item.ctime`]="{ item }">
                <span>
                  {{ moment(item.ctime).format('DD-MM-YYYY HH:mm:ss')}}
                </span>
              </template>
               <template #[`item.mtime`]="{ item }">
                <span>
                  {{ (item.mtime) ? moment(item.mtime).format('DD-MM-YYYY HH:mm:ss') : ''}}
                </span>
              </template>
              <template #[`item.expiredToken`]="{ item }">
                <span>
                  {{ (item.expiredToken) ? 'tak' : 'nie'}}
                </span>
              </template>
              <template #[`item.firebaseToken`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    > {{item.firebaseToken.substring(0,30)+'...'}}</span>
                  </template>
                  <span> {{item.firebaseToken}}</span>
                </v-tooltip>
              </template>
              <template #[`item.notificationActive`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="activateNotfication(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :color="(item.notificationActive) ? 'green' : 'grey'"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ (item.notificationActive) ? 'wyłącz' : 'włącz' }}
                  </span>
                </v-tooltip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      link
                      :to="`/callin/application/${item.id}/edit`"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edycja</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-snackbar
              v-model="snackbar"
              color="orange"
            >
              {{ snackbarText }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>
                  mdi-close-thick
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </template>
        </div>
        <div 
          class="text-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="200"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Devices',
    data() {
      return {
        headers: [
          {
            text: 'Id',
            value: 'id'
          },
          {
            text: 'Data stworzenia',
            value: 'ctime'
          },
          {
            text: 'Data modyfikcaji',
            value: 'mtime'
          },
          {
            text: 'Model',
            value: 'model'
          },
          {
            text: 'wygasły Token',
            value: 'expiredToken'
          },
          {
            text: 'Fingerprint',
            value: 'fingerprint'
          },
          {
            text: 'Token',
            value: 'firebaseToken',
          },
          {
            text: 'OS',
            value: 'os'
            },          
          {
            text: 'Aktywne notyfikcje',
            value: 'notificationActive'
          },
          {
            text: 'Akcje',
            value: 'actions'
          },
        ],
        applications: [],
        snackbar: false,
        snackbarText: '',
        deleteModal: false,
        selectedApplication: null,
        loading: false,
        filterToken: false,
      };
    },
    watch: {
    },
    mounted() {
      this.loading = true;
      this.fetchApplications();
    },
    methods: {
      fetchApplications() {
        this.axios.get('/api/callin/applications')
        .then(( {status, data} ) => {
          if (status !== 200) {
            throw new Error('Wrong status code');
          }
          this.snackbar = true;
          this.snackbarText = 'Pobrano dane';
          this.applications = data;
          this.loading = false;
        });
      },
      activateNotfication(item) {
        const postData = {
          active: (item.notificationActive === true) ? 0 : 1,
        }
        this.axios.patch(`/api/callin/application/${item.id}/activate`, postData, { 'Content-Type': 'application/json' })
          .then(({ status }) => {
            if (status !== 200) {
              throw new Error('Wrong status code');
            }
            this.snackbar = true;
            this.snackbarText = 'Zapisano';
            this.fetchApplications();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  }
</script>
<style>
.v-snack__content{
  color:#000;
}
</style>