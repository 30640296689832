<template>
  <v-card
    :class="
      $vuetify.breakpoint.xs
        ? 'text-center pl-2 pr-2'
        : 'text-center pl-10 pr-10'
    "
    elevation="0"
    v-if="!loading"
  >
    <template>
      <v-card-title
        :class="
          $vuetify.breakpoint.xs
            ? 'text-center fullwidth mt-5 pl-0'
            : 'fullwidth mt-2 pl-0'
        "
      >
        {{this.componentTitle}}
        <v-spacer />
        <v-card-subtitle class="pr-0" style="text-align: right">
          data ważności modułu <br />
          <b>{{ this.expireDate }}</b>
        </v-card-subtitle>
      </v-card-title>
      <v-card class="d-flex justify-end" elevation="0">
        <v-card-actions>
          <v-btn
            color="secondary"
            tile
            @click="savePassword"
            :loading="this.loading"
            :disabled="this.loading"
          >
            <v-icon left> mdi-content-save-outline </v-icon>
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
    <v-card elevation="0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="group.name"
          label="Nazwa"
          required
          :rules="[(v) => !!v || 'Pole wymagane']"
          :disabled="this.loading"
          prepend-icon="mdi-format-text-variant"
        ></v-text-field>
        <template v-if="isEdit">
          <h5 align="left" class="mt-4">
            data modyfikacji:
            {{ moment(group.mtime).format("YYYY-MM-DD HH:mm:ss") }}
          </h5>
          <h5 align="left" class="mt-4">
            data utworzenia:
            {{ moment(group.ctime).format("YYYY-MM-DD HH:mm:ss") }}
          </h5>
        </template>
      </v-form>
    </v-card>
  </v-card>
  <div class="text-center mt-15 pt-15" v-else>
    <v-progress-circular indeterminate color="primary" :size="200" />
  </div>
</template>

<script>
export default {
  name: "PasswordsAddEdit",
  data() {
    return {
      group: {
        name: "",
      },
      snackbar: false,
      snackbarText: "",
      isEdit: false,
      valid: true,
      loading: false,
      nameRules: [
        (v) => !!v || "Name Pole wymagane",
        (v) => (v && v.length <= 32) || "Name must be less than 32 characters",
      ],
      expireDate: '-',
      componentTitle: 'Dodaj grupę',
    };
  },
  watch: {},
  mounted() {
    this.isEdit = this.checkIfEdit(this.$route.params.id);
    if (this.isEdit) {
      this.componentTitle = 'Edytuj grupę';
      this.fetchGroupDetails(this.$route.params.id);
    } else {
      this.fetchExpireDate();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    checkIfEdit(id) {
      if (id !== undefined) {
        return true;
      }
      return false;
    },
    savePassword() {
      this.loading = true;
      if (this.isEdit) {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .patch(
              `/api/pzp/passwords-groups/${this.$route.params.id}`,
              this.group,
              { "Content-Type": "application/json" }
            )
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/passwords/groups");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      } else {
        const formValid = this.$refs.form.validate();
        if (formValid) {
          this.axios
            .post("/api/pzp/passwords-groups", this.group, {
              "Content-Type": "application/json",
            })
            .then(({ status }) => {
              if (status !== 200) {
                throw new Error("Wrong status code");
              }
              this.loading = false;
              this.$router.push("/passwords/groups");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.loading = false;
        }
      }
    },
    fetchGroupDetails(id) {
      this.loading = true;
      this.axios
        .get(`/api/pzp/passwords-groups/${id}`, {
          "Content-Type": "application/json",
        })
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.loading = false;
          this.group = data.data;
          this.expireDate = data.expire_date;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchExpireDate() {
      this.loading = true;
      this.axios
        .get(`/api/pzp/passwords-check-expire`, {
          "Content-Type": "application/json",
        })
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Wrong status code");
          }
          this.loading = false;
          this.expireDate = data.expire_date;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-snack__content {
  color: #000;
}
</style>