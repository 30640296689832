
import Vue from 'vue';

const state = () => ({
  status: '',
  token: localStorage.getItem('token') || null,
  refresh_token: localStorage.getItem('refresh_token') || null,
  components: [],
  user: {},
});

const getters = {
  isLoggedIn: (state) => state.token !== null,
  authStatus: (state) => state.status,
  components: (state) => state.components,
  user: (state) => state.user,
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, { accessToken, refreshToken, components, user }) {
    state.status = 'success';
    state.token = accessToken;
    state.refresh_token = refreshToken;
    state.components = components;
    state.user = user;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = null;
    state.refresh_token = null;
    state.components = [];
    state.user = {};
  },
};

const actions = {
  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      const postData = {
        'username': user.username,
        'password': user.password,
      }
      Vue.axios.post('/api/login_check', postData, { 'Content-Type': 'application/json' })
        .then((resp) => {
          const accessToken = resp.data.token;
          const refreshToken = resp.data.refresh_token;
          const components = resp.data.components;
          const user = resp.data.user;
          localStorage.setItem('token', accessToken);
          localStorage.setItem('refresh_token', refreshToken);
          Vue.axios.defaults.headers.common.Authorization = accessToken;
          commit('auth_success', { accessToken, refreshToken, components, user });
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          reject(err);
        });
    });
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      delete Vue.axios.defaults.headers.common.Authorization;
      sessionStorage.clear();
      resolve();
    });
  },
  async refresh({ commit, state }) {
    const refreshToken = localStorage.getItem('refresh_token');
    const { user } = state;

    return new Promise((resolve, reject) => {
      commit('auth_request');
      const postData = {
        'refresh_token': refreshToken
      };
      Vue.axios.post('/token/refresh', postData, { 'Content-Type': 'application/json' })
        .then((resp) => {
          const token = resp.data.token;
          const refreshToken = resp.data.refresh_token;
          localStorage.setItem('token', token);
          localStorage.setItem('refresh_token', refreshToken);
          Vue.axios.defaults.headers.common.Authorization = token;
          commit('auth_success', { token, refreshToken, user });
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
