<template>
  <v-app>
  <v-app-bar 
    app
    v-if="isLogged"
  >
    <v-app-bar-nav-icon
      @click.stop="openDrawer"
    />
    <v-toolbar-title
      style="width: 300px"
      class="ml-0 pl-4"
    >
    <span class="hidden-sm-and-down">{{ appName }}</span>
    </v-toolbar-title>
    <v-spacer />
     <v-btn
      class="ma-2"
      icon
      @click.stop="userDrawer"
    >
      <v-icon>
        mdi-cog
      </v-icon>
    </v-btn>
    <v-btn
      class="ma-2"
      icon
      @click="storeLogout"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
  <v-main>
    <pzp-drawer 
      ref="menu"
      v-if="isLogged"
      :dark-mode="darkMode"
    />
    <user-drawer
      ref="userMenu"
      v-if="isLogged"
      @toggleDarkMode="toggleDarkMode"
    />
    <router-view 
      ref="view"
      :dark-mode="darkMode"
    >
    </router-view>
  </v-main>
  <v-footer app v-if="!this.$vuetify.breakpoint.xs">
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PzpDrawer from './components/PzpDrawer.vue';
import UserDrawer from './components/UserDrawer.vue';

export default {
  name: 'App',
  components: {
    PzpDrawer,
    UserDrawer,
  },
  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      this.darkMode = true;
    } else {
      this.$vuetify.theme.dark = localStorage.getItem('darkMode') === 'true';
      this.darkMode = localStorage.getItem('darkMode') === 'true';
    }
  },
  beforeMount() {
  },
  data () {
      return {
        darkMode: false,
        right: null,
      }
    },
  computed: {
    appName: () => {
      const env = process.env.VUE_APP_ENV;
      if (env === 'development') {
        return 'Pan z Pogodna';
      }
      return 'Pan z Pogodna';
    },
    appColor: () => {
      const env = process.env.VUE_APP_ENV;

      if (env === 'test') {
        return 'error';
      }

      return 'secondary';
    },
    ...mapGetters({
      isLogged: 'auth/isLoggedIn',
    }),
  },
  watch: {
    async isLogged(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.$router.push('/login');
      }else{
        this.$router.push('/');
      }
    },
  },
  methods: {
    openDrawer() {
      this.$refs.menu.toggleDrawer();
    },
    userDrawer() {
      this.$refs.userMenu.toggleDrawer();
    },
    toggleDarkMode(newValue){
      this.$vuetify.theme.dark = newValue;
      this.darkMode = newValue;
      localStorage.setItem('darkMode', this.darkMode);
    },
    ...mapActions({
      storeLogout: 'auth/logout',
    }),
  },
};
</script>
<style>
#app {
    background-color: var(--v-background-base);
}
.v-main{
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>